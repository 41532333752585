@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.container {
  padding: 12px 20px;
  background-color: $color-white;
  display: grid;
  gap: 8px 12px;
  grid-template-rows: auto auto auto;
  grid-template-columns: 51px auto 37px;
  grid-template-areas: "image name new"
                        "details details details"
                        "button button button";
  @include breakpoint(desktop-screen) {
    padding: 12px 32px;
    gap: 0px 18px;
    grid-template-rows: auto auto;
    grid-template-columns: 82px auto auto;
    grid-template-areas: "image name button"
                        "image details button";
  }
  
  img {
    width: 51px;
    height: 51px;
    margin-top: 13px;
    grid-area: image;
    justify-self: left;
    align-self: start;
    @include breakpoint(desktop-screen) {
      width: 82px;
      height: 82px;
      margin-top: 0;
      align-self: center;
    }
  }

  .nameWrap {
    grid-area: name;
    justify-self: left;
    align-self: end;
    @include breakpoint(desktop-screen) {
      width: 100%;
      overflow: hidden;
    }

    h4 {
      font-family: $font-display;
      font-weight: 300;
      letter-spacing: 0.005em;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 0.005em;
      color: $color-ui-grey-5;
      span {
        font-size: 24px;
        line-height: 28px;
        color: $color-black;
        display: block;
      }

      @include breakpoint(desktop-screen) {
        font-size: 32px;
        line-height: 38px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        span {
          font-size: 32px;
          line-height: 38px;
          display: inline;
          &::before {
            content: ' - ';
          }
        }
      }
    }

    h5 {
      font-weight: 500;
      padding: 12px 4px 10px;
      color: $color-ui-grey-5;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .newTag {
    grid-area: new;
    justify-self: right;
    align-self: start;
    margin-top: 13px;
    height: 20px;
    font-family: $font-sans;
    font-weight: 500;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding: 0 6px;
    background-color: $color-sand;
    border-radius: 100px;
    @include breakpoint(desktop-screen) {
      grid-area: image;
      justify-self: left;
      margin-top: 0;
      transform: translate(-27px, -7px);
      
    }
  }

  .detailsWrap {
    grid-area: details;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px 20px;
    padding: 8px 4px;
  }

  button {
    grid-area: button;
    justify-self: center;
    align-self: end;
    margin-top: 8px;
    :global(.svg-icon--arrow) {
      stroke: $color-white;
      stroke-width: 0.5px;
      fill: $color-white;
    }
    @include breakpoint(desktop-screen) {
      justify-self: end;
      align-self: center;
      margin-top: 0;
    }
  }
  

}