@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';


.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  text-align: center;
  width: 100%;
  padding: 40px 16px 0;
  background-color: $color-white;
}