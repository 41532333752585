@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  width: 100%;
  @include breakpoint(desktop-screen) {
    width: fit-content;
    padding: 0 60px 30px;
    background-color: $color-ui-grey-6;
  }

  .header {
    width: 100%;
    padding: 38px;
    background-color: $color-ui-grey-6;
    @include breakpoint(desktop-screen) {
      padding: 16px 35px;
    }

    h2 {
      font-style: normal;
      font-size: 28px;
      line-height: 32px;
      text-align: center;
      text-transform: capitalize;
      @include breakpoint(desktop-screen) {
        font-size: 32px;
        line-height: 38px;
        white-space: nowrap;
      }
    }
  }

  .formWrap {
    padding: 20px 32px;
    background-color: $color-white;
    display: flex;
    justify-content: center;

    form {
      gap: 24px;
      width: 100%;
      @include breakpoint(desktop-screen) {
        width: 480px;
      }
    }
  }

  .btnWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
  }
}

