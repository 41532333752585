@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.outer {
  @include breakpoint(desktop-screen) {
    width: 382px;
  }
}

.container {
  height: 48px;
  width: 100%;
  max-width: 48px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  transition: max-width 0.28s ease-out;
  &[data-reveal=true] {
    max-width: 382px;
  }
  &[data-always-collapsible=false] {
    @include breakpoint(desktop-screen) {
      max-width: 382px;
      transition: unset;
    }
  }
  &[data-always-collapsible=true] {
    @include breakpoint(desktop-screen) {
      transform: translateX(0px);
      transition: max-width 0.28s ease-out, transform 0.28s ease-out;
      &[data-reveal=false] {
        transform: translateX(334px);
      }
    }
  }
  

  .icon {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: $color-sand;

    svg {
      width: 24px;
      height: 24px;
    }

    &[data-active=true] {
      background-color: $color-black;
      svg {
        fill: $color-white
      }
    }
  }

  input {
    width: 100%;
    height: 100%;
    padding: 0 16px;
    background-color: $color-dusty-pink;
    &[data-active=true] {
      background-color: $color-black;
      color: $color-white;
      &::placeholder {
        color: $color-ui-grey-3;
      }
    }
    @include breakpoint(desktop-screen) {
      width: 300px;
    }
  }

  input::placeholder {
    font-family: $font-display;
    font-style: italic;
    font-size: 24px;
    letter-spacing: 0.005em;
    color: rgba(22, 25, 28, 0.3);
  }

  &[data-is-widget=true] {
    .icon {
      background-color: $color-widget-med-blue;
    }
    input {
      background-color: $color-widget-faint-blue;
      &[data-active=true] {
        background-color: $color-widget-med-blue;
        &::placeholder {
          color: $color-ui-grey-3;
        }
      }
    }
  }
}

.tagsContainer {
  width: 100%;
  max-width: 382px;
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 12px;
}