@mixin breakpoint($point) {
	@if $point == large-desktop-screen {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $point == xl-desktop-screen {
		@media (min-width: 1500px) {
			@content;
		}
	} @else if $point == desktop-screen {
		@media (min-width: 992px) {
			@content;
		}
	} @else if $point == tablet-screen {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point == small-screen {
		@media (min-width: 576px) {
			@content;
		}
	} @else if $point == phone-screen {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point == sm-phone-screen {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point == xs-phone-screen {
		@media (max-width: 575px) {
			@content;
		}
	}
}

@mixin mobile-menu($bg-color, $text-color)  {
  background-color: $bg-color;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: grid;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.3s ease-out;
  /* visibility: hidden; */

  &.show {
    transform: translate3d(0%, 0, 0);
    /* visibility: visible; */
  }

  .close-btn {
    top: 14px;
    right: 16px;
  }

  ul {
    grid-area: 1/1;
    justify-self: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    color: $text-color;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    li {
      font-family: $font-sans;
    }
  }

  .social-media-wrap {
    grid-area: 1/1;
    justify-self: center;
    align-self: end;
    margin-bottom: 48px;
		@include breakpoint(desktop-screen) {
      display: none;
    }
  }
}


