a {
  text-decoration: none;
  outline: none;

  &:focus,
  &:active,
  &:hover {
    outline: none;
  }
}

a.underline {
  text-decoration: underline;
  text-underline-offset: 4px;
}

a,
button,
input[type=submit],
input[type=button] {
  font-family: $font-sans;
  text-transform: uppercase;
  &[disabled] {
    cursor: not-allowed;
  }
}

.btn,
input[type=submit].btn,
input[type=button].btn {
  letter-spacing: 2px;
  width: 100%;
  max-width: 311px;
  height: 50px;
  padding: 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: $color-black;
  border-radius: 2px;
  color: $color-white;
  border-color: $color-white;
  &:hover {
    background-color: $color-hover-black;
  }

  * {
    pointer-events: none;
  }

  svg,
  .svg-icon {
    height: 24px;
    width: 24px;
  }

  &--small {
    height: 30px !important;
    font-size: 12px;

    svg,
    .svg-icon {
      height: 14px;
      width: 14px;
    }
  }

  &--border {
    border-width: 1px;
    border-style: solid;
  }

  &--white {
    background-color: $color-white;
    color: $color-black;
    border-color: $color-black;
    &:hover {
      background-color: $color-off-white;
    }
  }

  &--bone {
    background-color: $color-bone-white;
    color: $color-black;
    border-color: $color-black;
    &:hover {
      background-color: darken($color-bone-white, 2);
    }
  }

  &--grey {
    background-color: $color-accent-grey;
    border-color: $color-black;
    &:hover {
      background-color: darken($color-accent-grey, 4);
    }
  }

  &--grey-light {
    color: $color-black;
    background-color: $color-warm-grey-light;
    border-color: $color-black;
    &:hover {
      background-color: darken($color-warm-grey-light, 4);
    }
  }

  &--grey-6 {
    color: $color-black;
    background-color: $color-ui-grey-6;
    border-color: $color-black;
    &:hover {
      background-color: darken($color-ui-grey-6, 4);
    }
  }

  &--transparent {
    color: $color-black;
    background-color: rgba(0,0,0,0);
    border-color: $color-black;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &--dusty-pink {
    background-color: $color-dusty-pink;
    color: $color-black;
    border-color: $color-black;
    &:hover {
      background-color: darken($color-dusty-pink, 4);
    }
  }

  &--sand {
    background-color: $color-sand;
    color: $color-black;
    border-color: $color-black;
    &:hover {
      background-color: darken($color-sand, 4);
    }
  }

  &--widget-blue {
    background-color: $color-widget-dark-blue;
    border-color: $color-widget-dark-blue;
    &:hover {
      background-color: darken($color-widget-dark-blue, 4);
    }
    &.btn--border{
      color: $color-widget-dark-blue;
      background-color: transparent;
      &:hover {
        background-color: $color-widget-faint-blue;
      }
    }
  }

  &--destructive {
    background-color: $color-error;
    color: $color-white;
    border-color: $color-black;
    &:hover {
      background-color: darken($color-error, 4);
    }
  }

  &--destructive-alt {
    background-color: $color-white;
    color: $color-error;
    border-color: $color-error;
    &:hover {
      background-color: $color-off-white;
    }
  }

  &--oval {
    width: min-content;
    height: 37px;
    font-size: 14px;
    line-height: 16px;
    border-radius: 100px;
    white-space: nowrap;
    padding: 0 16px;
    /* .svg-icon {
      height: 20px;
      width: 20px;
    } */
  }

  &--white.btn--oval {
    border-color: $color-ice-grey;
  }

  &--dt-wide {
    @include breakpoint(desktop-screen) {
      width: 100%;
      max-width: 520px;
    }
  }

  &--auto-width {
    width: auto;
  }

  &--pad-0 {
    padding: 0;
  }

  &--pad-16 {
    padding: 0 16px;
  }

  &[disabled] {
    border: none;
    background-color: $color-accent-grey;
    color: $color-ui-grey-6;
    &:hover {
      background-color: $color-accent-grey;
      color: $color-ui-grey-6;
    }

    svg {
      fill: $color-ui-grey-6;
    }
  }

  &--file {
    display: grid;
    justify-content: center;
    font-family: $font-serif;
    font-style: italic;
    font-weight: 500;
    font-size: 20px;
    text-transform: none;

    >* {
      grid-area: 1/1;
      align-self: center;
      cursor: pointer;
    }

    span {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }

    input {
      opacity: 0;
      height: 100%;
      width: 100%;
      pointer-events: all;
    }

    &[disabled] {
      * {
        pointer-events: none;
      }
    }
  }
}

.close-btn {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}