@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  display: flex;
  flex-direction: column;
  min-height: 546px;
  @include breakpoint(desktop-screen) {
    flex-direction: row-reverse;
    height: 100%;
  }

  > * {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    padding: 16px 32px 32px;
    @include breakpoint(desktop-screen) {
      padding: 60px 32px 100px;
    }
  }
}

.orderWrap {
  background-color: $color-dusty-pink;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include breakpoint(desktop-screen) {
      padding: 0 32px 20px;
    }

    .trashBtn {
      width: 24px;
      height: 24px;
    }
  }

  :global(.form-like) {
    opacity: 0;
    transition: opacity 0.34s 0.2s linear;

    &[data-ready=true] {
      opacity: 1;
    }
  }

  input[name=item-price],
  input[name=subtotal] {
    font-style: italic;
    color: $color-hover-black;
  }

  .couponWrap {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 70px;
    opacity: 0.75;

    &[data-valid=true] {
      opacity: 1;
    }

    div {
      width: 100%;
    }

    button {
      width: min-content;
      font-size: 14px;
      padding: 0 24px;
    }
  }

  select:global(.valid),
  label[for=item-price] {
    font-family: $font-sans;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  select:global(.invalid) {
    color: $color-hover-black;
  }

  :global(.form-sub-group) {
    border-color: $color-black;
    font-family: $font-display;
    padding-left: 0;
    padding-right: 0;
    height: min-content;
    &[data-disabled=true] {
      background-color: initial !important;
      color: initial !important;
    }

    input {
      font-family: $font-display !important;
    }
  }

  .subtotal {
    margin-top: 100px;
    padding-top: 0;
    padding-bottom: 0;
    
    label {
      color: $color-hover-black;
    }
  }

  .total {
    font-family: $font-display;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.paymentWrap {
  background-color: $color-white;
  
  form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    opacity: 0;
    transition: opacity 0.34s 0.2s linear;

    &[data-ready=true] {
      opacity: 1;
    }
  }
}

.divide {
  width: 100%;
  display: grid;

  >* {
    grid-area: 1/1;
  }

  span {
    width: fit-content;
    display: block;
    background-color: $color-white;
    justify-self: center;
    padding: 0 26px;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: $color-accent-grey;
    align-self: center;
  }
}
