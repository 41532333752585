@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.linkWrap {
  margin-top: 16px !important;
}

.inputError {
  background-color: $color-off-white;
  border-color: $color-error !important;
}