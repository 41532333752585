.social-media-wrap {
  display: grid;

  &.size-24 {
    grid-template-columns: repeat(3, 24px);
    grid-template-rows: 24px;
    gap: 16px;
  }

  &.size-21 {
    grid-template-columns: repeat(3, 21px);
    grid-template-rows: 21px;
    gap: 14px;
  }

  &.alt-order {
    .fb-link {
      grid-area: 1/2;
    }

    .insta-link {
      grid-area: 1/1;
    }
  }

  .fb-link {
    grid-area: 1/1;
  }

  .insta-link {
    grid-area: 1/2;
  }

  .in-link {
    grid-area: 1/3;
  }
}