@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  @include breakpoint(desktop-screen) {
    display: grid;
    grid-template-columns: 60.2112676056338% auto;
  }
}

.quoteWrap {
  @include breakpoint(desktop-screen) {
    grid-area: 1/2;
    padding-top: 170px;
  }

  .quoteContent {
    @include breakpoint(desktop-screen) {
      border-radius: 0px 100px 0px 0px;
      padding: 127px 57px 127px 24px;
    }
  }
}

.formWrap {
  @include breakpoint(desktop-screen) {
    width: 100% !important;
    grid-area: 1/1;
  }
}

.checkLinkWrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 12px 0 0;
  @include breakpoint(desktop-screen) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  a {
    text-transform: none;
    font-family: $font-sans;
  }
}

:global(.link-wrap) {
  p {
    text-align: center;
    margin-top: 2px;
    white-space: normal;
  }
}