@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content-wrap {
  padding: 0;
  @include breakpoint(desktop-screen) {
    padding-bottom: 100px;
  }

  .header {
    width: 100%;
    padding: 40px 0 52px;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint(desktop-screen) {
      padding: 48px 0 32px;
    }

    h1 {
      font-size: 52px;
      line-height: 52px;
      font-style: normal;
      @include breakpoint(desktop-screen) {
        font-size: 60px;
        line-height: 64px;
      }
    }
  }

  .listHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 38px;
    background-color: $color-ui-grey-6;
    @include breakpoint(desktop-screen) {
      flex-direction: row;
      justify-content: space-between;
      padding: 16px 35px 20px;
    }
  }

  h2 {
    font-style: normal;
    font-size: 28px;
    line-height: 32px;
    text-transform: capitalize;
    @include breakpoint(desktop-screen) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .ordersContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 32px 22px 53px;
    margin: 16px 16px 32px;
    background-color: $color-white;
    @include breakpoint(desktop-screen) {
      padding: 32px 35px 48px;
      margin: 0;
      background-color: $color-ui-grey-6;
    }

    h2,p[data-mobile] {
      padding: 0 10px;
      @include breakpoint(desktop-screen) {
        padding: 0;
      }
    }

    p[data-mobile] {
      font-size: 18px;
      line-height: 20px;
      color: $color-hover-black;
    }
  }

  .bottonBtnWrap {
    width: 100%;
    padding: 16px 32px 36px;
    background-color: $color-ui-grey-6;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
  }

}