@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.container {
  width: 100%;
  padding: 10px 10px 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-bottom: 2px solid $color-black;
  @include breakpoint(desktop-screen) {
    display: grid;
    grid-template-columns: 330px auto minmax(270px, 284px) 122px;
    grid-template-rows: 48px;
    grid-template-areas: "name-price blank date button";
    gap: unset;
    /* justify-content: space-between; */
    align-items: center;
    border-bottom: none;
    padding: 0;
  }

  .namePrice {
    @include breakpoint(desktop-screen) {
      grid-area: name-price;
      width: 100%;
      height: 100%;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      border-bottom: 1px solid $color-black;
    }
    
  }

  .name {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-decoration: underline;
    text-underline-offset: 3px;
    margin-bottom: 8px;
    @include breakpoint(desktop-screen) {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 2px;
      text-decoration: none;
      margin-bottom: 0;
    }
  }

  .date {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1.5px;
    color: $color-hover-black;
    @include breakpoint(desktop-screen) {
      grid-area: date;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 2px;
      padding-right: 71px;
    }
  }

  .price {
    font-family: $font-display;
    font-style: italic;
    font-size: 18px;
    line-height: 20px;
    color: $color-hover-black;
    @include breakpoint(desktop-screen) {
      
    }

    span {
      font-family: $font-serif;
      font-style: normal;
    }
  }

  .btnWrap {
    margin-top: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    @include breakpoint(desktop-screen) {
      grid-area: button;
      width: auto;
      margin-top: 0;
    }
  }
}