@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.icon {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: $color-sand;
  border-radius: 4px;
  z-index: 1;
  svg {
    width: 24px;
    height: 24px;
    pointer-events: none;
  }

  &[data-notify-count]::after {
    /* top: 6px; */
    color: $color-white;
    background-color: $color-black;
  }

  &[data-is-widget=true] {
    background-color: $color-widget-med-blue;
  }
}

.container {
  height: auto;
  width: 100%;
  max-width: 48px;
  max-height: 48px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: $color-ice-grey;
  position: absolute;
  transition: max-width 0.28s ease-out;
  z-index: 0;

  &[data-reveal=true] {
    box-shadow: 0px 2px 20px rgb(29 30 35 / 8%);
    max-width: calc(100% - 32px);
    max-height: 1000px;
    z-index: 2;
    @include breakpoint(tablet-screen) {
      max-width: 446px;
    }
  }

  &[data-overflow=true] {
    overflow: visible;
  }

  &[data-is-jobboard=true] {
    position: relative;
    overflow: visible;
    max-height: unset;
    max-width: 100%;
    background-color: $color-dusty-pink;
    @include breakpoint(desktop-screen) {
      max-width: 740px;
    }

    .row {
      form {
        @include breakpoint(tablet-screen) {
          flex-direction: row;
          div {
            min-width: calc(50% - 8px);
          }
        }
      }
    }
  }

  &[data-is-widget=true] {
    background-color: $color-widget-light-blue !important;
    .icon {
      background-color: $color-widget-dark-blue;
    }
    input {
      background-color: $color-widget-faint-blue;
    }
    :global(.form-sub-group) {
      background-color: $color-widget-faint-blue !important;
      :global(.svg-icon) {
        fill: $color-widget-med-blue;
      }
    }
  }

  .icon {
    background-color: $color-black;
    border-radius: 0;
    border-top-right-radius: 4px;
    svg {
      fill: $color-white;
    }
  }

  :global(.eyebrow) {
    color: $color-hover-black;
    font-weight: 500;

    &[data-filter-by] {
      font-size: 10px;
      line-height: 12px;
    }
  }

  .titleRow {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &[data-32] {
      height: 32px;
    }

    span {
      margin-left: 25px;
    }
  }

  .row {
    padding: 16px;
    form {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .inputSelectWrap {
    :global(.form-sub-group) {
      width: 100%;
      background-color: $color-white;
      border-radius: 4px;
      border: none;
      padding-right: 16px;
    }

    option[value=default] {
      display: inherit;
    }
  }

  .locationWrap {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;

    &[data-loc-selected=true] {
      :global(.form-sub-group)[data-disabled=true] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .radiusDDWrap {
      flex-shrink: 0;
      width: 99px;
      :global(.form-sub-group) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .filtersWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0 16px 16px;
  }

}