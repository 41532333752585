@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.user-menu-container {
  position: absolute;
  @include breakpoint(desktop-screen) {
    width: auto;
    height: auto;
    top: 100%;
    right: 18px;
    z-index: 49;
    overflow: hidden;
    pointer-events: none;
  }
}

.user-menu-wrap {
  @include mobile-menu($color-sand, $color-black);
  @include breakpoint(desktop-screen) {
    width: auto;
    height: auto;
    position: relative;
    transform: translate3d(0, -100%, 0);
    &.show {
      transform: translate3d(0, 0%, 0);
      pointer-events: all;
    }
  }

  .close-btn {
    @include breakpoint(desktop-screen) {
      width: 100%;
      height: 36px;
      top: 0;
      right: 0;

      .svg-icon {
        width: 12px;
        height: 12px;
        position: absolute;
        top: 12px;
        right: 16px;
      }
    }
  }

  .active {
    border-bottom: 2px solid $color-black;
  }

  ul {
    .svg-icon {
      display: none;
    }
    @include breakpoint(desktop-screen) {
      font-size: 14px;
      gap: 10px;
      padding: 36px;

      li, li a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }

      li {
        height: 32px;
        cursor: pointer;

        /* &:nth-last-child(2) {
          border-bottom: 2px solid $color-black;
        } */
      }

      .svg-icon {
        display: inline-block;
        height: 24px;
        width: 24px;
      }
    }
  }
}