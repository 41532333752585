@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.recruiter-wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 16px;
  @include breakpoint(desktop-screen) {
    flex-direction: row;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding:0 16px;
    text-align: center;
    @include breakpoint(desktop-screen) {
      padding: 0 50px;
    }

    p {
      text-align: left;
      max-width: 600px;
    }
  }

  .titleWrap {
    @include breakpoint(desktop-screen) {
      margin-top: 32px;
    }
  }

  .listWrap {
    padding: 40px 24px;
    background-color: $color-bone-white;
    @include breakpoint(desktop-screen) {
      padding: 40px;
      width: 436px;
      flex-shrink: 0;
    }

    ul {
      font-family: $font-display;
      font-style: italic;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      flex-direction: column;
      gap: 18px;
      margin: 18px 0 2px;
      list-style: none;

      li {
        position: relative;
      }

      svg {
        width: 24px;
        height: 24px;
        position: absolute;
        left: -36px;
        top: 2px;
        stroke: $color-bone-white;
        stroke-width: 1.2px;
      }
    }
  }
}