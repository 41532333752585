@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 32px 24px;
  background-color: $color-white;
  border-radius: 4px;
  @include breakpoint(desktop-screen) {
    max-width: (892 + 64)+px;
    gap: 20px;
    padding: 32px;
  }

  :global(.load-error),
  :global(.loading-inview) {
    font-size: 28px !important;
    line-height: 32px !important;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
  }

  [data-changed=true] {
    outline: 1px solid $color-error;
  }

  .modifiedHeader {
    font-weight: 700;
    text-align: center;
    &[data-modified=true] {
      color: $color-error
    }
    &[data-modified=false] {
      color: $color-success;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    @include breakpoint(desktop-screen) {
      justify-content: flex-start;
    }

    h1 {
      width: fit-content;
      font-size: 28px;
      line-height: 32px;
      font-style: normal;
      @include breakpoint(desktop-screen) {
        font-size: 48px;
        line-height: 52px;
        font-style: normal;
        flex-shrink: 0;
      }

      .companyName {
        color: $color-hover-black;
        font-size: 75%;
      }
    }
  }

  .share {
    width: 100%;
    display: flex;
    justify-content: center;
    @include breakpoint(desktop-screen) {
      height: 52px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .descWrap {
    color: $color-hover-black;
    display: flex;
    flex-direction: column;
    gap: 12px;

    >* {
      margin: 0;
    }
  }

  .detailsWrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    @include breakpoint(desktop-screen) {
      flex-direction: row;
      padding: 30px 0 42px;
    }

    h2 {
      font-style: normal;
      font-weight: 400;
    }

    .details {
      width: 100%;
      flex-grow: 1;
      height: fit-content;
      padding: 32px 24px;
      color: $color-black;
      @include breakpoint(desktop-screen) {
        width: 30%;
      }

      > * {
        white-space: pre-wrap;
        word-break: break-word;
      }

      &[data-responsibilities] {
        background-color: $color-bone-white;
        svg {
          stroke: $color-bone-white;
        }
      }

      &[data-qualifications] {
        background-color: $color-dusty-pink;
        svg {
          stroke: $color-dusty-pink;
        }
      }

      &[data-compensation] {
        background-color: $color-sand;
        svg {
          stroke: $color-sand;
        }
      }

      ul {
        font-family: $font-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        flex-direction: column;
        gap: 18px;
        margin: 18px 0 2px;
        list-style: none;
  
        li {
          position: relative;
        }
  
        svg {
          width: 24px;
          height: 24px;
          position: absolute;
          left: -36px;
          top: 2px;
          stroke-width: 1px;
        }
      }
    }
  }

  .revealBtn {
    width: fit-content;
    height: 34px;
    color: $color-ui-grey-5;
    display: flex;
    align-items: center;
    gap: 8px;
    :global(.svg-icon) {
      fill: $color-ui-grey-5;
      height: 28px;
      width: 28px;
    }
    
    &:hover {
      color: $color-black;
      :global(.svg-icon) {
        fill: $color-black;
      }
    }
  }
}