@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.container {
  @include breakpoint(desktop-screen) {
    padding: 40px 80px;
  }
  
  .content {
    opacity: 0;
    transition: opacity 0.32s ease-out;
    border-radius: 100px 0 0 0;
    background-color: $color-sand;
    @include breakpoint(desktop-screen) {
      flex-direction: row;
      background: linear-gradient(to bottom right, rgba(0,0,0,0) 7%, $color-sand 7%, $color-sand 100%);
    }

    &[data-loaded=true] {
      opacity: 1;
    }
  }

  :global(.eyebrow) {
    text-align: center;
  }

  p:not(:global(.eyebrow)) {
    color: $color-hover-black;
  }

  .msgWrap {
    width: 100%;
    padding: 48px 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    
    @include breakpoint(desktop-screen) {
      width: 430px;
      height: fit-content;
      padding: 112px 32px;
      gap: 32px;
      flex-shrink: 0;
      border-radius: 100px 2px;
      background-color: $color-warm-grey-light;
      box-shadow: 0px 12px 40px 10px rgba(0, 0, 0, 0.1);
    }

    p:not(:global(.eyebrow)) {
      display: none;
      @include breakpoint(desktop-screen) {
        display: initial;
      }
    }
  }

  .formWrap {
    width: 100%;
    flex-grow: 1;
    padding: 0 24px 60px;
    @include breakpoint(desktop-screen) {
      padding: 60px 154px 60px 74px;
    }

    :global(form) {
      gap: 24px;
    }

    :global(.form-sub-group) {
      background-color: transparent;
      color: $color-black;
      &[data-disabled] {
        background-color: transparent !important;
        color: rgba($color-overlay-black, 0.6) !important;
      }
    }

    :global(.form-sub-group--border) {
      border-color: $color-black;
      &[data-disabled] {
        border-color: $color-overlay-black;
      }
    }
  }

  .btnWrap {
    text-align: center;
    p {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.5px;
      margin-bottom: 12px;
    }
  }
}