@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.container {
  position: relative;
  width: 100%;

  &[data-disabled] {
    pointer-events: none;
  }

  .optionsContainer {
    position: absolute;
    width: 100%;
    margin-top: -2px;
    z-index: 6;

    .gLogo {
      width: 100%;
      padding: 4px 8px 6px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 3px;
      font-size: 9px;
      line-height: 10px;
      font-family: $font-sans;
      background-color: $color-warm-grey-light;
      color: #5F6368;

      img {
        height: 16px;
      }
    }

    :global(.form-sub-group.input-option) {
      font-family: $font-sans;
      font-size: 12px;
      text-transform: uppercase;
      color: $color-hover-black;
      border-radius: 0;
      background-color: $color-warm-grey-light;
      :global(.input-icon .svg-icon) {
        fill: $color-white;
      }

      @include breakpoint(desktop-screen) {
        font-size: 16px;
      }
    }

    :global(.form-sub-group.input-option:hover) {
      color: $color-black;
      background-color: $color-salmon;
      :global(.input-icon .svg-icon) {
        fill: $color-black;
      }
    }
  }
}