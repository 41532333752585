@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

 .hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 32px 24px 0;
  position: relative;
  @include breakpoint(desktop-screen) {
    gap: 56px;
    padding: 40px 28px 0;
  }

  .bg {
    position: absolute;
    top: -32px;
    height: 100%;
    width: 120%;
    background: linear-gradient(to bottom, $color-dusty-pink 55.5%, 
                $color-sand 55.5%, $color-sand 99.3%, $color-white 99.3%, $color-white 100%);
    z-index: 0;
    @include breakpoint(desktop-screen) {
      top: -40px;
      height: 811px;
      width: 400%;
      background: linear-gradient(to right, $color-dusty-pink 50%, 
                $color-sand 50%, $color-sand 100%);
    }
  }

  h1 {
    max-width: 414px;
    z-index: 1;
    text-align: center;

    @include breakpoint(desktop-screen) {
      font-weight: 400;
      font-size: 60px;
      line-height: 64px;
      max-width: 592px;
      letter-spacing: 0.005em;
    }
  }

  .ctasWrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    z-index: 1;
    @include breakpoint(desktop-screen) {
      flex-direction: row;
      gap: 0;
    }

    > div {
      display: grid;

      >* {
        grid-area: 1/1;
      }

      picture {
        display: grid;
        justify-content: center;
      }

      img {
        width: 100%;
        max-width: 654px;
        @include breakpoint(desktop-screen) {
          max-width: unset;
        }
      }

      a,
      button {
        width: 311px;
        justify-self: center;
        align-self: end;
        margin-bottom: 24px;
        @include breakpoint(desktop-screen) {
          margin-bottom: 40px;
        }
      }
    }
  }
}