.container {
  display: grid;
  grid-template-columns: repeat(3, 24px);
  grid-template-rows: 24px;
  gap: 12px;

  .fbLink {
    grid-area: 1/1;
  }

  .inLink {
    grid-area: 1/2;
  }

  .emailLink {
    grid-area: 1/3;
  }
}