@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  display: flex;
  flex-direction: column-reverse;
  @include breakpoint(desktop-screen) {
    display: grid;
    grid-template-columns: 39.436619718309857% auto;
  }
}

.quoteWrap {
  @include breakpoint(desktop-screen) {
    grid-area: 1/1;
    padding-top: 170px;
  }

  .quoteContent {
    @include breakpoint(desktop-screen) {
      gap: 8px;
      border-radius: 100px 0px 0px 2px;
      padding: 60px;
    }
  }
}

.formWrap {
  border-radius: 100px 2px 0px 0px;
  @include breakpoint(desktop-screen) {
    width: 100%;
    grid-area: 1/2;
    padding: 51px 105px 72px;
  }

  :global(.form-sub-group)[data-disabled=true] {
    background-color: transparent !important;
  }

  &::before {
    border-radius: 100px 2px 0px 0px;
  }
}

.checkboxWrap {
  margin: 12px 0 8px;
}