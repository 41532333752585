@import '/src/scss/settings/vars';

.container {
  width: 371px;
  padding: 50px 24px;
  background: $color-dusty-pink;
  border-radius: 2px 100px 2px 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
}