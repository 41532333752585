@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.header {
  width: 100%;
  padding: 32px 16px 24px;
  background-color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  @include breakpoint(desktop-screen) {
    background-color: $color-ui-grey-6;
    max-width: $max-width-content;
  }

  .wrap {
    height: 108px;
    width: 291px;
    display: flex;
    justify-content: space-between;
    position: relative;
    @include breakpoint(desktop-screen) {
      width: 376px;
    }
  }

  .step1,
  .step2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    > *:last-child {
      width: 133px;
      padding: 12px;
      font-family: $font-sans;
      font-weight: 400;
      font-size: 14px;
      font-style: normal;
      line-height: 16px;
      text-align: center;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  .step2 {
    > *:last-child[data-step=complete-profile] {
      color: $color-accent-grey;
    }
  }

  .iconWrap {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &[data-step=complete-profile] {
      background-color: $color-white;
      border: 2px solid $color-accent-grey;
      :global(.svg-icon--checkmark) {
        fill: $color-accent-grey;
      }
      @include breakpoint(desktop-screen) {
        background-color: $color-ui-grey-6;
      }
    }

    &[data-step=submit-job-post],
    &[data-step=apply-to-job] {
      background-color: $color-black;
      :global(.svg-icon--checkmark) {
        fill: $color-white;
      }
      @include breakpoint(desktop-screen) {
        :global(.svg-icon--checkmark) {
          fill: $color-ui-grey-6;
        }
      }
    }

    :global(.svg-icon--checkmark) {
      height: 18px;
      width: 18px;
    }
  }

  .dot {
    height: 12px;
    width: 12px;
    background-color: $color-black;
    border-radius: 50%;
  }

  .line {
    position: absolute;
    width: 50%;
    height: 4px;
    left: 88px;
    top: 18px;
    background-color: $color-nuetral-grey;
    border-radius: 6px;
    z-index: 1;
    &[data-step=submit-job-post] {
      background-color: $color-black;
    }
    @include breakpoint(desktop-screen) {
      width: 55%;
    }
  }
}