@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.twirl-down-container {
  width: 100%;

  .twirl-down-title-wrap {
    width: auto;
    font-family: $font-sans;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    letter-spacing: 2px;
    text-transform: uppercase;

    span {
      width: 24px;
      height: 24px;
    }

    &[data-reveal=true] {
      color: $color-stone-grey;
    }
  }

  .twirl-down-children-wrap {
    height: max-content;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.34s ease-out;
    &[data-reveal=true] {
      overflow-y: auto;
    }
  }
}