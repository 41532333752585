@import '/src/scss/settings/vars';

.checkbox-wrap {
  display: flex;
  align-items: center;
  cursor: pointer;

  * {
    cursor: pointer;
  }

  .form-sub-group {
    align-items: center;
    padding: 0 4px;
    gap: 0px;
  }

  input[type=checkbox],
  input[type=radio] {
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    display: flex;
    align-items: center;
    height: 16px;
    width: 16px;
    padding: 2px;
    margin-right: 12px;
    background-color: $color-ui-grey-4;
    transition: background-color 0.25s ease-out;

    .svg-icon {
      transform: scale(0);
      transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }

  .label-flex {
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-family: $font-sans;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 2px;

    .sub-label {
      font-family: $font-serif;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 20px;
      color: $color-accent-grey;
    }
  }

  input:checked ~ .checkmark {
    background-color: $color-sand;
    .svg-icon {
      transform: scale(1);
    }
  }
}