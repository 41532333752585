@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.nav-wrap {
  padding: 20px 0;
  margin: 0 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  position: relative;

  &[data-admin] {
    grid-template-columns: repeat(3, 200px);
  }

  &[data-admin="1"] {
    .line[data-index="3"],
    .line[data-index="4"],
    .line[data-index="5"] {
      visibility: hidden;
    }
  }

  &[data-admin="2"] {
    .line[data-index="0"],
    .line[data-index="1"],
    .line[data-index="2"] {
      visibility: hidden;
    }
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 16px;

    * {
      pointer-events: none;
    }

    :global(.eyebrow) {
      white-space: normal;
      text-align: left;
    }

    &:nth-child(1) {
      grid-area: 1/1;
    }
    &:nth-child(2) {
      grid-area: 1/2;
    }
    &:nth-child(3) {
      grid-area: 1/3;
    }

    &:nth-child(4) {
      grid-area: 1/4;
    }

    &:nth-child(5) {
      grid-area: 1/5;
    }

    .iconWrap {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      flex-shrink: 0;
      display: grid;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 0;

      &[data-active=true] {
        background-color: $color-black;
      }

      svg {
        width: 24px;
        height: 24px;
      }

      :global(.svg-icon--star) {
        fill: none;
        stroke-width: 2px;
        &:global(.earthy-grey) {
          stroke: $color-earthy-grey;
        }
        &:global(.white) {
          stroke: $color-white;
        }
      }
    }
  }

  .line {
    width: calc(50% - 16px);
    height: 2px;
    background-color: $color-black;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate3d(0%, 0%, 0px);
    transition: transform 0.3s cubic-bezier(.1,.5,.7,1.5);

    &[data-length="3"] {
      width: 200px;

      &[data-index="1"] {
        transform: translate3d(calc(100% + 32px), 0%, 0px);
      }
  
      &[data-index="2"] {
        transform: translate3d(calc(200% + 64px), 0%, 0px);
      }
  
      &[data-index="4"] {
        transform: translate3d(calc(100% + 32px), 0%, 0px);
      }
  
      &[data-index="5"] {
        transform: translate3d(calc(200% + 64px), 0%, 0px);
      }
    }

    &[data-right=true] {
      transform: translate3d(calc(100% + 32px), 0%, 0px);
    }
  }
}