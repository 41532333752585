@import '/src/scss/settings/vars';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .label {
    text-decoration: underline;
    text-underline-offset: 12px;
    margin-bottom: 6px;
  }

  :global(.checkbox-wrap) {
    width: fit-content;
    min-width: 274px;
    padding: 16px 20px;
    background: $color-white;
    border-radius: 2px;

    :global(.checkmark) {
      margin-right: 26px;
    }
  }

  p {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
  }

  .attatchWrap {
    font-family: $font-sans;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $color-hover-black;
    
    :global(.btn--file) {
      margin-left: 8px;
      width: min-content;
      white-space: nowrap;
      text-transform: uppercase;
      font-family: $font-sans;
      font-style: normal;
      font-size: 10px;
      line-height: 12px;
      display: inline-grid;
      text-decoration: underline;
      text-underline-offset: 4px;
      color: $color-black;
    }
  }
}