@import '/src/scss/settings/vars';

.btn {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  gap: 12px;
  
  span {
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    background-color: $color-salmon;
    pointer-events: none;
  }

  &[disabled] {
    color: $color-nuetral-grey;
    span {
      background-color: $color-nuetral-grey;
    }
  }
}

.valuesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  &[data-empty=true] {
    margin-top: -12px;
  }

  .tag {
    display: flex;
    align-items: center;
    gap: 6px;
    background: $color-salmon;
    border-radius: 4px;
    padding: 0 6px;

    &[data-hidden=true] {
      height: 0;
      width: 0;
      visibility: hidden;
    }

    input {
      pointer-events: none;
    }

    button {
      height: 16px;
      width: 16px;
      flex-shrink: 0;
    }
  }
}