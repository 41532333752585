@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.container {
  position: fixed;
  top: $header-height-mobile;
  left: 0;
  width: 100%;
  height: calc(100% - $header-height-mobile);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(0,0,0,0.1);
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
  @include breakpoint(desktop-screen) {
    top: $header-height-desktop;
    height: calc(100% - $header-height-desktop);
  }
  &[data-show=false] {
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: visibility 0.35s linear, opacity 0.2s linear;
  }

  .modal {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: fit-content;
    max-width: 600px;
    padding: 48px;
    margin: 16px;
    background-color: $color-white;
    box-shadow: 0px 0px 16px 4px rgba(0,0,0,0.08);
    border-radius: 4px;
    overflow: hidden;
    opacity: 0;
    transform: scale(0.85);
    transition: opacity 0.2s linear, transform 0.34s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    &[data-show=true] {
      opacity: 1;
      transform: scale(1);
    }
  }
}