@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.newsletter-wrap {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 32px 24px 80px;
  @include breakpoint(desktop-screen) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 127px 24px 90px;
  }

  >* {
    flex-shrink: 1;
  }

  .bg {
    position: absolute;
    height: 100%;
    width: 120%;
    top: 0;
    left: -20%;
    background: linear-gradient(to bottom, $color-white 95%, 
                $color-nuetral-grey 95%, $color-nuetral-grey 100%);
    z-index: 0;
    @include breakpoint(desktop-screen) {
      width: 400%;
      left: -200%;
      background: linear-gradient(to bottom, $color-white 82%, 
                $color-nuetral-grey 82%, $color-nuetral-grey 100%);
    }
  }

  img {
    width: 100%;
    aspect-ratio: 1/1;
    position: relative;
    z-index: 1;
    @include breakpoint(desktop-screen) {
      max-width: 533px;
    }
  }

  .content {
    max-width: 600px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    position: relative;
    z-index: 1;
    @include breakpoint(desktop-screen) {
      max-width: 464px;
    }
  }

  .titleWrap {
    text-align: left;
    max-width: 403px;

    p:last-child {
      margin-top: 24px;
      text-align: left;
      color: $color-hover-black;
    }
  }

  form {
    width: 100%;
    max-width: 464px;
    gap: 24px;
    @include breakpoint(desktop-screen) {
      gap: 16px;
    }

    [data-testid="form-component"] {
      button {
        padding: 0 32px !important;
        border-radius: 2px !important;
        background-color: $color-white !important;
        color: $color-black !important;
        border-color: $color-black !important;
        text-transform: uppercase;
        width: 100% !important;
        max-width: 311px !important;
        &:hover {
          background-color: $color-off-white !important;
        }
      }

      &:has(label):has(input) {
        padding: 0 !important;
        margin-bottom: 16px !important;

        > div {
          flex-direction: row !important;
          align-items: center;
          gap: 8px;
          padding: 7px 8px 8px;
          position: relative;
          border-bottom: 1px solid $color-ui-grey-4 !important;

          label {
            min-width: 46px;
            padding: 0 !important;
            font-size: 18px !important;
            font-weight: 400 !important;
            font-family: $font-serif !important;
            letter-spacing: .005em !important;
            line-height: 28px !important;
          }

          input {
            border: none !important;
            flex-grow: 1 !important;
            font-family: $font-sans !important;
            font-size: 18px !important;
            font-weight: 400 !important;
            line-height: 28px !important;
            letter-spacing: 0.005em !important;
            height: auto !important;
            border-radius: 0 !important;
            padding: 0 0 0 6px !important;
          }

          > div:last-child {
            position: absolute !important;
          }

          ::placeholder {
            color: $color-white !important;
          }
          
          ::-ms-input-placeholder {
            color: $color-white !important;
          }
        }
      }
    }
  }



  /* .btnWrap {
    margin: -12px 0 12px;
    display: flex;
    justify-content: center;
    @include breakpoint(desktop-screen) {
      margin: 0;
    }
  } */



}