@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.header {
  text-align: center;
  :global(.eyebrow) {
    white-space: pre-wrap;
    @include breakpoint(desktop-screen) {
      white-space: nowrap;
    }
  }
}


.listWrap {
  padding-bottom: 24px;

  ul {
    font-family: $font-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 18px;
    line-height: 28px;
    gap: 18px;
    margin: 18px 0 2px;
  }
}

.listWrap,
.gridContentWrap {
  ul {
    display: flex;
    flex-direction: column;
    list-style: none;

    li {
      position: relative;
    }

    svg {
      width: 24px;
      height: 24px;
      position: absolute;
      left: -36px;
      top: 2px;
      stroke-width: 1px;
    }
  }
}

.topContentWrap {
  height: auto;
  padding-bottom: 74px;
  @include breakpoint(desktop-screen){
    padding-bottom: 60px;
  }

  p:not(:global(.eyebrow)) {
    color: $color-hover-black;
  }

  :global(.content) {
    display: flex;
    flex-direction: column;
    background-color: $color-black;
    @include breakpoint(desktop-screen){
      display: grid;
      grid-template-columns: 304px 126px auto;
      grid-template-rows: auto auto;
      grid-template-areas: "post single single"
                          "blank multi multi";
      background-color: transparent;
    }

    >* {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
    }
  }
  .postWrap {
    padding: 73px 42px;
    background-color: $color-sand;
    box-shadow: 0px 12px 40px 10px rgba(0, 0, 0, 0.1);
    border-radius: 2px 2px 100px 2px;
    text-align: center;
    @include breakpoint(desktop-screen) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-end: 2;
      z-index: 1;
      height: min-content;
      padding: 99px 44px;
    }

    :global(.svg-icon--chevron) {
      @include breakpoint(desktop-screen) {
        transform: rotate(-90deg);
      }
    }
  }

  .singleWrap {
    color: $color-white;
    background-color: $color-black;
    padding: 44px 32px 60px;
    @include breakpoint(desktop-screen) {
      grid-area: single;
      padding: 60px 124px 60px 188px;
    }

    .listWrap {
      width: 184px;
      @include breakpoint(desktop-screen) {
        width: 100%;
        max-width: 464px;
      }
    }
  }

  .multiWrap {
    background-color: $color-white;
    border: 8px solid $color-sand;
    padding: 60px 32px;
    @include breakpoint(desktop-screen) {
      grid-area: multi;
    }

    .listWrap {
      @include breakpoint(desktop-screen) {
        width: 100%;
        max-width: 464px;
      }
       ul {
        svg {
          stroke: $color-white;
        }
       }
    }
  }

  .singleWrap,
  .multiWrap {
    .header {
      width: 166px;
      @include breakpoint(desktop-screen){
        width: auto;
      }
    }
  }
  
}

.gridContentWrap {
  height: auto;
  padding: 60px 32px 100px;
  background-color: $color-bone-white;
  @include breakpoint(tablet-screen){
    padding: 80px 32px 92px;
  }

  ul {
    margin: 0;
    @include breakpoint(tablet-screen){
      padding: 0;
    }
    li {
      margin: 8px;
    }
    svg {
      stroke: $color-bone-white;
    }
  }

  ul, p {
    font-family: $font-display;
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.12px;
  }

  :global(.eyebrow) {
    white-space: normal;
    @include breakpoint(tablet-screen){
      font-size: 20px;
      line-height: 24px;
    }
  }

  :global(.content) {
    display: grid;
    /* gap: 32px; */
    grid-template-columns: auto auto;
    grid-template-areas: "header1 header1" "subheader1 subheader1" "content1-1 content1-1"
                         "subheader2 subheader2" "content2-1 content2-1"
                         "subheader3 subheader3" "content3-1 content3-1"
                         "subheader4 content4-1"
                         "header2 header2" "subheader1-extra subheader1-extra" "content1-2 content1-2"
                         "subheader2-extra subheader2-extra" "content2-2 content2-2"
                         "subheader3-extra subheader3-extra" "content3-2 content3-2"
                         "subheader4-extra content4-2";
    @include breakpoint(tablet-screen){
      gap: 32px;
      grid-template-columns: 24.8% 1fr 1fr;
      grid-template-areas: ". header1 header2"
                            "subheader1 content1-1 content1-2"
                            "separator1 separator1 separator1"
                            "subheader2 content2-1 content2-2"
                            "separator2 separator2 separator2"
                            "subheader3 content3-1 content3-2"
                            "separator3 separator3 separator3"
                            "subheader4 content4-1 content4-2";
    }

    [data-extra] {
      @include breakpoint(tablet-screen){
        display: none;
      }
    }

    [data-header] {
      @include breakpoint(tablet-screen){
        padding-bottom: 32px;
      }
    }

    [data-header="1"] {
      grid-area: header1;
    }

    [data-header="2"] {
      grid-area: header2;
      padding-top: 32px;
      border-top: 1px solid $color-stone-grey;
      @include breakpoint(tablet-screen){
        padding-top: 0;
        border-top: none;
      }
    }

    [data-header] {
      text-align: center;
      @include breakpoint(tablet-screen){
        text-align: left;
      }
    }

    [data-subheader] {
      margin: 32px 0  8px;
      @include breakpoint(tablet-screen){
        margin: 0;
      }
    }

    [data-subheader="1"] {
      grid-area: subheader1;
      &[data-extra] {
        grid-area: subheader1-extra;
      }
    }

    [data-subheader="2"] {
      grid-area: subheader2;
      &[data-extra] {
        grid-area: subheader2-extra;
      }
    }

    [data-subheader="3"] {
      grid-area: subheader3;
      &[data-extra] {
        grid-area: subheader3-extra;
      }
    }

    [data-subheader="4"] {
      grid-area: subheader4;
      &[data-extra] {
        grid-area: subheader4-extra;
      }
    }

    [data-content]:not([data-content="4-2"]) {
      padding-bottom: 32px;
      @include breakpoint(tablet-screen){
        padding-bottom: 0;
      }
    }

    [data-content]:not([data-content="4-1"]):not([data-content="4-2"]) {
      border-bottom: 1px solid $color-stone-grey;
      @include breakpoint(tablet-screen){
        border-bottom: none;
      }
    }

    [data-content="4-1"],
    [data-content="4-2"] {
      margin-top: 32px;
      @include breakpoint(tablet-screen){
        margin-top: 0;
      }
    }

    [data-content="1-1"] {
      grid-area: content1-1;
    }

    [data-content="1-2"] {
      grid-area: content1-2;
    }

    [data-content="2-1"] {
      grid-area: content2-1;
    }

    [data-content="2-2"] {
      grid-area: content2-2;
    }

    [data-content="3-1"] {
      grid-area: content3-1;
    }

    [data-content="3-2"] {
      grid-area: content3-2;
    }

    [data-content="4-1"] {
      grid-area: content4-1;
    }

    [data-content="4-2"] {
      grid-area: content4-2;
    }

    [data-separator="1"],
    [data-separator="2"],
    [data-separator="3"] {
      display: none;
      @include breakpoint(tablet-screen){
        display: block;
        width: 100%;
        height: 1px;
        background-color: $color-stone-grey;
      }
    }

    [data-separator="1"] {
      grid-area: separator1;
    }

    [data-separator="2"] {
      grid-area: separator2;
    }

    [data-separator="3"] {
      grid-area: separator3;
    }
  }
}

.bottomContentWrap {
  height: auto;
  padding: 60px 32px 114px;
  background-color: $color-warm-grey-light;
  @include breakpoint(desktop-screen){
    padding: 32px 32px 60px;
  }

  p:not(:global(.eyebrow)) {
    color: $color-hover-black;
  }

  :global(.content) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .pWrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}