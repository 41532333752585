.modal-container {
  position: absolute;
  left: 0;
  top: $header-height-mobile;
  width: 100%;
  height: calc(100% - $header-height-mobile);
  padding: 16px;
  background: rgba(22, 25, 28, 0.8);
  z-index: 30;
  overflow-y: auto;
  @include breakpoint(desktop-screen) {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    top: $header-height-desktop;
    height: auto;
    min-height: calc(100% - $header-height-desktop);
    padding: 80px;
  }

  .modal-content {
    position: relative;
    width: 100%;
    height: fit-content;
    max-width: $max-width-content;
    display: flex;
    flex-direction: column;
  }
}