@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.contentWrap {
  padding: 16px 16px 64px;
  @include breakpoint(desktop-screen) {
    padding: 32px 32px 96px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    text-align: center;
    padding: 32px 16px;
    background-color: $color-bone-white;
    border-radius: 2px;
    box-shadow: 0px 12px 40px 10px rgba(0, 0, 0, 0.1);
    @include breakpoint(desktop-screen) {
      padding: 103px 80px;
      border-radius: 2px 2px 100px 2px;
      box-shadow: none;
    }
  
    p:not(:global(.eyebrow)) {
      color: $color-hover-black;
    }
  }
}


