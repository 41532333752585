form, .form-like {
  display: flex;
  flex-direction: column;

  .form-sub-group,
  .form-sub-sub-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 7px 8px 8px;
    position: relative;
    &--border {
      border-bottom: 1px solid $color-ui-grey-4;
    }

    &--column {
      flex-direction: column;
      align-items: flex-start;
    }

    &--stretch {
      align-items: stretch;
      label,
      .input-icon {
        align-self: center;
      }
    }

    &--inline {
      margin-inline: auto;
    }

    .select-wrap {
      flex-grow: 1;
    }

    &.radio-group-outer {
      flex-direction: column;
      align-items: flex-start;
      padding: 7px 0 8px;
      
    }

    &.radio-group-label-wrap {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    &.radio-group-children-wrap {
      padding: 0 12px;
      p {
        color: $color-hover-black;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &.fieldset-wrap,
    &.radio-group-children-wrap {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    &.fieldset-wrap {
      padding-left: 12px;
      padding-right: 12px;
      fieldset {
        display: flex;
        flex-direction: column;
        gap: 4px;
        
        label {
          display: flex;
          gap: 10px;
        }

        input[type=radio] {
          opacity: 0;
          height: 0;
          width: 0;
        }

        .radio {
          height: 28px;
          width: 16px;
          display: flex;
          align-items: center;
          .svg-icon {
            height: 16px;
            border: 2px solid #ABB8C3;
            border-radius: 50%;
            use {
              visibility: hidden;
            }
          }
        }

        input[type=radio]:checked ~ .radio {
          .svg-icon {
            border: none;
            border-radius: unset;
            use {
              visibility: visible;
            }
          }
        }
      }
    }

    .input-error {
      position: absolute;
      bottom: -19px;
    }

    .has-label:not(fieldset)  {
      text-align: right;
    }

    &.wide-label {
      label {
        min-width: 200px;
      }
    }

    &.restrict-label-mobile {
      label {
        max-width: 200px;
        @include breakpoint(tablet-screen) {
          max-width: unset;
        }
      }
    }
  }

  .form-sub-sub-group {
    padding-top: 8px;
  }

  .form-sub-group[data-disabled=true] {
    background-color: $color-ui-grey-6 !important;
    color: $color-ui-grey-3 !important;
    pointer-events: none;
    input::placeholder {
      color: $color-ui-grey-3 !important;
    }
  }
}

textarea,
select,
input:not([type=checkbox]):not([type=radio]) {
  flex-grow: 1;
  font-family: $font-sans;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.005em;
}

/* select,
input:not([type=checkbox]):not([type=radio]):not([type=date]) {
  text-transform: uppercase;
} */

/* input::placeholder {
  font-family: $font-serif;
  color: $color-hover-black;
  text-transform: none !important;
} */

.input-error {
  font-size: 13px !important;
  line-height: 15px !important;
  font-family: $font-sans !important;
  color: $color-error !important;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;

  &.inline {
    display: inline-flex;
  }
}

textarea,
textarea:focus {
  width: 100%;
  padding: 8px 12px 9px;
  border-radius: 8px;
  border: 1px solid $color-ui-grey-1;
}

textarea::placeholder {
  font-family: $font-serif;
  color: $color-ui-grey-3;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.005em;
}

select {
  width: 100%;
  height: 100%;
  padding-right: 18px !important;
  -moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
  white-space: pre-wrap;
}

/* option[value=default] {
  display: none;
} */

/* select.invalid {
  color: $color-accent-grey;
} */

.input-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  z-index: 1;

  &--left {
    width: 24px;
    height: 24px;
    pointer-events: none;
  }

  &--triangle {
    width: 10px;
    height: 7px;
    pointer-events: none;
    position: absolute;
    right: 8px;
  }

  &--error {
    width: 18px;
    height: 18px;
    pointer-events: none;
  }

  &--arrow {
    width: 24px;
    height: 24px;
    border: 1px solid $color-white;
    cursor: pointer;
    pointer-events: all;
  }

  /* &--checkmark {
    border-radius: 50%;
    background-color: $color-ui-black;
  }

  .svg-icon--checkmark {
    margin-top: 1px;
    width: 65%;
    height: 65%;
    fill: $color-white;
  } */
}

/* HIDE INPUT ARROWS */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}