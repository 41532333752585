.quote {
  font-family: $font-display;
  font-style: italic;
  font-weight: 300;
  font-size: 42px;
  line-height: 42px;
  letter-spacing: -0.01em;
  @include breakpoint(desktop-screen) {
    font-size: 48px;
    line-height: 52px;
  }

  span {
    font-style: normal;
  }
}

.quote-wrap {
  width: 100%;
  @include breakpoint(desktop-screen) {
    padding-top: 170px;
  }

  .quote-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-sand;
    padding: 60px 16px;
    @include breakpoint(desktop-screen) {
      height: 100%;
      justify-content: center;
    }
  }
}