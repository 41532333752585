@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  padding: 32px 16px;

   h1 {
    font-family: $font-sans;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .num {
      font-family: $font-sans;
      font-weight: 700;
      font-size: 146px;
      line-height: 146px;
    }

    .text {
      display: flex;
      flex-direction: column;
      text-align: justify;
      font-size: 36px;
      line-height: 36px;
    }
   }
}