@import 'react-date-picker/dist/DatePicker.css';
@import 'react-calendar/dist/Calendar.css';

.react-date-picker {
  flex-grow: 1;

  &__wrapper {
    justify-content: flex-end;
    border: none;
  }

  &__inputGroup {
    text-align: right;
  }
}

.react-calendar {

  &__month-view__days__day--weekend {
    color: $color-error;
  }

  &__tile {

    &--now {
      background: $color-ui-grey-3;
    }
    &--now:enabled:hover,
    &--now:enabled:focus {
      background: $color-ui-grey-4;
    }

    &--active {
      background: $color-salmon;
      color: $color-white;
    }

    &--active:enabled:hover,
    &--active:enabled:focus {
      background: $color-account-bg;
    }
  }
}