@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  display: flex;
  @include breakpoint(desktop-screen) {
    background-color:$color-dusty-pink;
  }

  .successWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 32px 16px;
    border-radius: 2px;
    background-color: $color-sand;
    box-shadow: 0px 12px 40px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    @include breakpoint(desktop-screen) {
      width: 50%;
      padding: 50px;
      border-radius: 2px 2px 100px 2px;
    }
  }

  .factWrap {
    display: none;
    @include breakpoint(desktop-screen) {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding: 119px 89px 119px 70px;
      border-radius: 2px;
    }

    .fact {
      font-family: $font-display;
      font-weight: 300;
      font-size: 32px;
      line-height: 38px;
    }
  }
}