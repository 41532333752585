@import '/src/scss/settings/vars';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    text-decoration: underline;
    text-underline-offset: 12px;
    margin-bottom: 6px;
  }
  
  textarea {
    background: $color-white;
  }
}