@import '/src/scss/settings/vars';

.detail {
  font-family: $font-sans;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  height: min-content;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  :global(.svg-icon) {
    height: 16px;
    width: 16px;
    fill: $color-stone-grey;
    flex-shrink: 0;
  }

  :global(.svg-icon--checkmark) {
    border-radius: 50%;
    border: 2px solid;
    padding: 1px;
  }

  &[data-lifecycle=active] {
    color: $color-success;
    border-color: $color-success;
    :global(.svg-icon) {
      fill: $color-success;
    }
  }

  &[data-lifecycle=deactivated],
  &[data-lifecycle=expired], 
  &[data-lifecycle=review]{
    color: $color-error;
    :global(.svg-icon) {
      fill: $color-error;
    }
  }
}