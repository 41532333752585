@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

header {
  width: 100%;
  height: $header-height-mobile;
  display: flex;
  justify-content: center;
  background-color: $color-black;
  position: fixed;
  top: 0;
  z-index: 50;

  @include breakpoint(desktop-screen) {
    height: $header-height-desktop;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    @include breakpoint(tablet-screen) {
      padding: 0 28px;
    }
  }

  .logo {
    width: 128px;
    height: 24px;
    @include breakpoint(desktop-screen) {
      width: 207px;
      height: 33px;
    }
  }

  .icons-wrap {
    display: flex;
    align-items: center;
    gap: 24px;
    z-index: -1;

    .menu {
      width: 20px;
      height: 12px;
      margin-bottom: 6px;
      @include breakpoint(desktop-screen) {
        display: none;
      }
    }
  
    .user {
      width: 24px;
      height: 24px;
      border: 1px solid $color-white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      > * {
        pointer-events: none;
      }

      img {
        border: 1px solid $color-white;
        width: 24px;
        height: 24px;
        background-color: unset;
      }
  
      .svg-icon--user {
        width: 12px;
        height: 12px;
      }

      .svg-icon--shiny-diamond {
        width: 14px;
        height: 14px;
      }
    }

  }
}