@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.container {
  gap: 20px;

  &[data-type=job] {
    padding: 26px 32px;
    @include breakpoint(desktop-screen) {
      padding: 24px 32px;
    }
  }

  &[data-type=application] {
    padding: 24px 16px;
    @include breakpoint(desktop-screen) {
      padding: 38px 32px;
    }
  }

  .titleMenuWrap {
    position: relative;
    &[data-type=application] {
      justify-content: center;
      @include breakpoint(desktop-screen) {
        justify-content: flex-start; 
      }
    }
  }

  .menu {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    gap: 16px;
  }

  .desc {
    color: $color-para-grey;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 12px; /* for <br> height */
    * {
      /* for line clamp to work in Safari */
      display: inline;
      line-height: 28px;
      @include breakpoint(desktop-screen) {
        line-height: 26px; 
      }
    }
  }

  .link {
    font-size: 14px;
    letter-spacing: 2px;
    color: $color-stone-grey;
    display: flex;
    align-items: center;
    gap: 8px;
    
    svg {
      fill: $color-stone-grey;
      height: 24px;
      width: 24px;
    }

    @include breakpoint(desktop-screen) {
      color: $color-black;
      svg {
        fill: $color-black;
      }
    }
  }

  :global(.btn[data-mobile=true]) {
    margin: 0 auto;
  }
}