@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {

  .searchWrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
    padding: 38px 38px 0;
    position: relative;
    background-color: $color-ui-grey-6;

    @include breakpoint(desktop-screen) {
      padding: 16px 35px 0;
    }

    :global(#job-filters-icon-btn) {
      &[data-notify-count]::after {
        top: 24px;
        right: 24px;
      }

      @include breakpoint(desktop-screen) {
        &[data-notify-count]::after {
          top: 3px;
          right: 22px;
        }
      }
    }

    .jobSearch {
      position: absolute;
      top: -($header-height-mobile - 10px);
      @include breakpoint(desktop-screen) {
        top: -($header-height-desktop - 10px);
      }
    }
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 38px;
    background-color: $color-ui-grey-6;
    @include breakpoint(desktop-screen) {
      flex-direction: row;
      justify-content: space-between;
      padding: 16px 35px 20px;

      .showingP {
        position: absolute;
        width: calc(100% - 70px);
        text-align: center;
        pointer-events: none;

        &[data-type="users"] {
          transition: width 0.28s ease-out;
          &[data-anim-width=true] {
            width: calc(100% - 452px);
          }
        }
      }
    }

    h2 {
      flex-shrink: 0;
      font-style: normal;
      font-size: 32px;
      line-height: 48px;
      text-transform: capitalize;
    }

    :global(.form-like) {
      width: fit-content;
      height: 36px;
      border-radius: 4px;
      background-color: #E6CFC3;

      :global(.form-sub-group--border) {
        border: none;
        padding: 0px 8px;
      }
      :global(.select-wrap) {
        background-color: $color-dusty-pink;
        padding: 4px 6px;

        select {
          width: 120%;
          padding-right: 20% !important;
        }
      }
    }
  }

  .jobsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    &[data-candidate] {
      background-color: $color-ui-grey-6;
      margin-bottom: 32px;
    }
    @include breakpoint(desktop-screen) {
      background-color: $color-ui-grey-6;
      padding: 4px 55px 32px;
      &[data-candidate] {
        margin-bottom: 0;
      }
    }
  }

  .bottonBtnWrap {
    width: 100%;
    padding: 16px 32px 36px;
    background-color: $color-white;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    @include breakpoint(desktop-screen) {
      padding: 0;
      background-color: unset;
    }
  }

  .searchFilterWrap {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-end;
    position: relative;
    width: 100%;
  }
}