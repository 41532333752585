@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.dropDownWrap {
  position: relative;

  .choicesContainer {
    position: absolute;
    z-index: 5;

    :global(.form-sub-group.input-option) {
      font-family: $font-sans;
      font-size: 12px;
      text-transform: uppercase;
      color: $color-hover-black;
      border-radius: 0;
      background-color: $color-warm-grey-light;
      :global(.input-icon--left) {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid #ABB8C3;
        svg {
          display: none
        }
      }
  
      @include breakpoint(desktop-screen) {
        font-size: 16px;
      }

      &[data-selected=true] {
        color: $color-black;
        :global(.input-icon--left) {
          border: none;
          svg {
            display: block;
          }
        }
      }
    }
  
    :global(.form-sub-group.input-option:hover) {
      color: $color-black;
      background-color: $color-salmon;
      :global(.input-icon--left) {
        border: none;
        svg {
          display: block;
        }
      }
    }
  }

  
}