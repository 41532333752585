@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.matchmaker-wrap {
  width: 100%;
  position: relative;
  @include breakpoint(desktop-screen) {
    height: 694px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-image: url(../../../images/hp-matchmaker-bg-m.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    @include breakpoint(desktop-screen) {
      background-image: url(../../../images/hp-matchmaker-bg.jpg);
      background-size: contain;
    }
  }

  .innerWrap {
    padding: 24px;
    position: relative;
    z-index: 1;
    @include breakpoint(desktop-screen) {
      width: $max-width-content;
      padding: 0;
      display: flex;
      justify-content: flex-end;
      
    }
    @media (min-width: 1310px) {
			width: 1440px;
      padding: 110px 65px 110px 533px;
		}
  }

  .content {
    padding: 40px 24px;
    background-color: $color-black;
    border-radius: 100px 2px 2px 2px;
    color: $color-white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    @include breakpoint(desktop-screen) {
      padding: 60px 102px 50px;
      width: 842px;
    }
  }

  .titleWrap {
    :global(.eyebrow) {
      color: $color-salmon;
    }
    h2 {
      padding: 0 20px;
      @include breakpoint(desktop-screen) {
        padding: 0;
      }
    }
  }

  .pWrap {
    max-width: 523px;
    p:first-child {
      margin-bottom: 24px;
      @include breakpoint(desktop-screen) {
        margin-bottom: 16px;
      }
    }
  }

  .btnWrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include breakpoint(desktop-screen) {
      flex-direction: row;
    }
  }
}