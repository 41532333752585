@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

@mixin roundBtn {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 1px solid $color-warm-grey-light;
  display: grid;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
  }
}

.container {
  padding: 28px 32px;
  gap: 26px;
  @include breakpoint(desktop-screen) {
    padding: 38px 32px;
  }

  h3 {
    white-space: unset;
  }

  .menuWrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    &[data-mobile] {
      justify-content: center;
      gap: 8px;
    }
    
    @include breakpoint(desktop-screen) {
      gap: 8px;
    }

    :global(.btn--sand) {
      height: 37px;
      width: 37px;
      font-size: 14px;
      line-height: 16px;

      svg, span {
        display: none;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      @include breakpoint(desktop-screen) {
        width: auto;
        svg, span {
          display: inline-block;
        }
      }
    }

    .editBtn {
      @include roundBtn;
    }

    .menuBtn {
      width: 24px;
      height: 24px;
      opacity: 1;
      @include breakpoint(desktop-screen) {
        @include roundBtn;
        transition: width 0.4s ease-out, opacity 0.4s ease-out;
        &[data-show=false] {
          width: 0px;
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    .hiddenBtnWrap {
      right: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      padding: 16px;
      width: 0px;
      height: 0px;
      pointer-events: none;
      overflow: hidden;
      opacity: 0;
      background-color: $color-white;
      /* box-shadow: 0px 2px 20px rgba(29, 30, 35, 0.08); */
      transition: opacity 0.3s ease-out;
      &[data-show=true] {
        width: 100%;
        max-width: fit-content;
        height: auto;
        opacity: 1;
        pointer-events: all;
      }
      @include breakpoint(desktop-screen) {
        position: absolute;
        height: auto;
        position: relative;
        padding: 0;
        flex-wrap: nowrap;
        width: 0%;
        /* box-shadow: none; */
        opacity: 1;
        transition: width 0.4s ease-out;
        &[data-show=true] {
          width: 100%;
        }
      }
    }
  }

  .detailsWrap {
    margin-right: 24px;
  }

  .candidatesWrap {
    display: flex;
    flex-direction: column;
    gap: 1px;
    padding: 1px;
    background-color: $color-stone-grey;
  }

  button[data-mobile] {
    align-self: center;
  }
}

.modalBtnWrap {
  width: 100%;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  
  @include breakpoint(desktop-screen) {
    background-color: transparent;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 22px 32px;

    > * {
      width: 30%;
      flex-grow: 1;
    }
  }
}