@import '/src/scss/settings/vars';

.tag {
  width: fit-content;
  /* height: 32px; */
  font-family: $font-sans;
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: $color-salmon;
  padding: 2px 4px 2px 6px;
  display: flex;
  align-items: center;
  gap: 13px;
  

  button {
    width: 18px;
    height: 18px;  
    * {
      pointer-events: none;
    }
  }

  &[data-is-widget=true] {
    color: $color-white;
    background-color: $color-widget-dark-blue;

    :global(.svg-icon) {
      fill: $color-white;
    }
  }
}