@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.contentWrap {
  background-color: $color-ui-grey-6;
  padding-bottom: 0px;
  @include breakpoint(desktop-screen) {
    background-color: $color-white;
    padding-bottom: 60px;
  }

  &[data-edit] {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.btnWrap{
  width: calc(100% + 32px);
  margin-top: 32px;
  padding: 16px 32px 36px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  @include breakpoint(desktop-screen) {
    width: 100%;
    margin-top: 0;
    padding: 0 24px;
    flex-direction: row;
    gap: 24px;
  }
}

