@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

footer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $color-black;
  color: $color-white;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 32px 16px;
    @include breakpoint(tablet-screen) {
      padding: 32px 28px;
    }
    @include breakpoint(desktop-screen) {
      flex-direction: row;
      align-items: flex-start;
      gap: 105px;
      padding: 128px 28px 95px;
    }
  }

  .logo {
    width: 217px;
    height: 59px;
    display: block;
  }

  .eyebrow {
    margin-bottom: 12px;
    color: $color-accent-grey;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.5px;
  }

  .contact-wrap {
    display: flex;
    gap: 62px;
    text-align: center;
    @include breakpoint(desktop-screen) {
      gap: 105px;
      text-align: left;
    }

    > div {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.5px;

      &:first-child a {
        margin: 4px 0;
      }
    }
  }

  form {
    flex-grow: 1;

    .form-sub-group {
      padding: 7px 0 8px;
      margin-bottom: 42px;
    }

    .btn-wrap {
      display: flex;
      gap: 24px;
      margin-bottom: 42px;
      @include breakpoint(desktop-screen) {
        margin-bottom: 16px;
      }
      @include breakpoint(large-desktop-screen) {
        gap: 50px;
      }

      .btn {
        padding: 0 8px;
        font-size: 14px;
      }
    }
  }
}