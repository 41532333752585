/* PALLETTE */
$color-black: #000;
$color-hover-black: #44494B;
$color-overlay-black: rgba(22, 25, 28, 0.6);
$color-white: #FFF;
$color-off-white: #F9F9F9;
$color-bone-white: #FFF9F6;
$color-sand: #E6CFC3;
$color-dusty-pink: #F6EDE9;
$color-warm-grey-light: #ECEDE9;
$color-warm-grey-dark: #E2E2DE;
$color-accent-grey: #AAAAAA;
$color-earthy-grey: #73716c;
$color-nuetral-grey: #D6D5D3;
$color-stone-grey: #A29D95;
$color-ice-grey: #DADFDE;
$color-ui-grey-1: #D3D3D3;
$color-ui-grey-2: #DADADA;
$color-ui-grey-3: #8899A6;
$color-ui-grey-4: #ABB8C3;
$color-ui-grey-5: #5C646C;
$color-ui-grey-6: #F5F5F5;
$color-para-grey: #434B54;
$color-error: #F03D3E;
$color-success: #25C274;
$color-salmon: #DBB5A4;
$color-account-bg: #F7D4C6;

/* Widget */
$color-widget-dark-blue: #035B9F;
$color-widget-med-blue: #28AAE2;
$color-widget-light-blue: #76BFDE;
$color-widget-faint-blue: #DEF1FA;
$color-widget-trans-blue: rgba(40, 170, 226, 0.37);
$color-widget-bg-grey: #F8F6F0;

/* OTHER VARS */
$max-width-content: 1136px;
$header-height-mobile: 52px;
$header-height-desktop: 81px;

/* FONTS */
$font-sans: 'Proxima Nova', sans-serif;
$font-serif: 'FreightText Pro', serif;
$font-display: freight-display-pro, serif;