@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.container {
  padding: 12px 20px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  @include breakpoint(desktop-screen) {
    padding: 12px 32px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .nameWrap {
    @include breakpoint(desktop-screen) {
      width: 66.666%;
      overflow: hidden;
    }

    h4 {
      font-family: $font-display;
      font-weight: 300;
      letter-spacing: 0.005em;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: 0.005em;
      color: $color-ui-grey-5;
      span {
        font-size: 24px;
        line-height: 28px;
        color: $color-black;
        display: block;
      }

      @include breakpoint(desktop-screen) {
        font-size: 32px;
        line-height: 38px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        span {
          font-size: 26px;
          line-height: 32px;
          display: inline;
          color: $color-hover-black;
        }
      }
    }
  }

  .resume {
    background-color: $color-ui-grey-6;
    &[data-active=true] {
      background-color: $color-sand;
    }
  }

  .status {
    background-color: $color-error;
    &[data-active=true] {
      background-color: $color-success;
    }
  }
  

  button {
    :global(.svg-icon--arrow) {
      stroke: $color-white;
      stroke-width: 0.5px;
      fill: $color-white;
    }
  }
}