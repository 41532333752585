@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.editorWrap {
  width: 100%;
  position: relative;
}

.editorInput {
  width: 100%;
  height: 176px;
  min-height: 40px;
  padding: 8px 12px 9px;
  border-radius: 8px;
  border: 1px solid $color-ui-grey-1;
  resize: vertical;
  overflow-y: scroll;

  >* {
    margin: 12px 0;
  }

  >*:first-child {
    margin-top: 0;
  }

  >*:last-child {
    margin-bottom: 0;
  }
}

.placeholder {
  font-family: $font-serif;
  color: $color-ui-grey-3;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.005em;
  position: absolute;
  left: 12px;
  top: 8px;
  pointer-events: none;
}

.toolbar {
  display: flex;
  gap: 8px;

  button {
    width: 32px;
    padding: 0;
    font-size: 16px;
    letter-spacing: unset;
    color: $color-accent-grey;
    border-color: $color-ui-grey-1;

    :global(.svg-icon) {
      fill: $color-accent-grey;
    }

    &:hover,
    &:active {
      color: $color-hover-black;
      :global(.svg-icon) {
        fill: $color-hover-black;
      }
    }

    &[data-active="true"] {
      color: $color-white;
      border-color: $color-black;
      background-color: $color-black;
      :global(.svg-icon) {
        fill: $color-white;
      }
    }

    &[data-type="bold"] {
      font-weight: 700;
    }

    &[data-type="italic"] {
      font-style: italic;
      font-family: "Georgia", "New Times Roman", serif;
      text-transform: lowercase;
    }
  }
}