.icon60 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
  }

  &--black {
    background-color: $color-black;
    .svg-icon--chevron {
      stroke-width: 1px;
      stroke: $color-white;
    }
  }

  &--border-black {
    border: 2px solid $color-black;
  }

  &--border-earthy-grey {
    border: 1px solid $color-earthy-grey;
  }

  
}