@import '/src/scss/settings/vars';

.favBtn {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: $color-white;
  border: 2px solid $color-warm-grey-dark;
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: visible;
  flex-shrink: 0;
  svg {
    height: 24px;
    width: 24px;
    fill: $color-white;
    stroke-width: 2px;
    stroke: $color-warm-grey-dark;
  }

  &[disabled] {
    background-color: $color-off-white;
    svg {
      fill: $color-off-white;
    }
  }

  &[data-is-favorited=true] {
    background-color: $color-dusty-pink;
    border: none;
    svg {
      fill: $color-salmon;
      stroke-width: none;
      stroke: none;
    }
  }

  &[data-is-widget=true] {
    border-color: $color-widget-med-blue;
    svg {
      stroke: $color-widget-med-blue;
    }
  }

  :global(.input-error) {
    position: absolute;
    right: 0;
    top: -19px;
    white-space: nowrap;
  }
}