@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.form {
  align-items: center;
  margin-top: 66px !important;
  gap: 16px;
}

.link1 {
  margin-top: 20px;
}

.link2 {
  margin-top: 4px;
}