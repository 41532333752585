.job-card {
  background-color: $color-white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  @include breakpoint(desktop-screen) {
    box-shadow: 0px 2px 20px rgba(29, 30, 35, 0.08);
  }
}

.job-card-title-menu-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  @include breakpoint(desktop-screen) {
    flex-wrap: nowrap;
  }

  h3 {
    font-family: $font-display;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.005em;
    color: $color-black;
    max-width: 78%;
    @include breakpoint(desktop-screen) {
      font-size: 32px;
      line-height: 38px;
      white-space: nowrap;
      width: 25%;
      flex-grow: 1;
    }

    .company-name {
      color: $color-hover-black;
      font-size: 75%;
    }
  }
}

.job-card-details-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}