@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.header-menu-wrap {
  @include mobile-menu($color-sand, $color-black);
  @include breakpoint(desktop-screen) {
    background-color: unset;
    position: unset;
    width: auto;
    height: auto;
    display: block;
    transform: unset;
    transition: unset;
    visibility: visible;
  }

  .close-btn {
    @include breakpoint(desktop-screen) {
      display: none;
    }
  }

  ul {
    @include breakpoint(desktop-screen) {
      display: flex;
      flex-direction: row;
      color: $color-white;
      font-weight: 500;
      font-size: 10px;
      letter-spacing: 1.5px;
    }
  }
}