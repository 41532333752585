@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px 40px 40px;
  background-color: $color-white;
  @include breakpoint(desktop-screen) {
    padding: 60px 40px 30px;
  }
}

.container {
  padding: 32px;
  background-color: $color-white;

  .moreInfo {
    grid-template-columns: auto;
    margin-top: 16px;
    display: flex !important;

    .left {
      margin-top: 24px;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 4px;

  img {
    margin-bottom: 16px;
  }

  .roleWrap{
    display: flex;
    align-items: center;
    gap: 20px;
  }

  h1 a,
  h2 a {
    text-transform: lowercase;
    font-family: $font-display;
    font-style: italic;
    font-weight: 300;
    color: $color-ui-grey-5;
  }

  h2 {
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.01em;
    @include breakpoint(desktop-screen) {
      font-size: 42px;
      line-height: 42px;
    }
  }

  &[data-applicant] {
    h1 {
      font-size: 38px;
      line-height: 38px;
      @include breakpoint(desktop-screen) {
        font-size: 42px;
        line-height: 48px;
      }
    }

    h2 {
      font-size: 24px;
      line-height: 24px;
      @include breakpoint(desktop-screen) {
        font-size: 28px;
        line-height: 28px;
      }
    }
  }
}

.moreInfo {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 16px;
  margin-top: 16px;
  @include breakpoint(desktop-screen) {
    display: grid;
    gap: 16px 24px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    grid-template-areas: "top top"
                          "left right";
  }

  .detailsWrap {
    grid-area: top;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    @include breakpoint(desktop-screen) {
      flex-direction: row;
      justify-content: center;
    }
  }

  .left, .right {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .left {
    grid-area: left;
    @include breakpoint(desktop-screen) {
      .infoP {
        justify-content: flex-start;
        gap: 16px;
        span {
          text-align: left;
        }
      }
    }
  }

  .right {
    grid-area: right;
    @include breakpoint(desktop-screen) {
      &[data-candidate] {
        padding-top: 28px;
      }
    }
  }

  .bioWrap {
    width: 100%;
    .bio {
      margin: 0 auto;
      width: 100%;
      min-height: 42px;
      padding: 8px 10px;
      background-color: $color-warm-grey-light;
      border-radius: 4px;

      /* p:not(:first-child) {
        margin-top: 8px;
      } */

      span {
        display: block;
        height: 16px;
      }

    }
  }

  .linkWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    a,div {
      text-transform: none;
      display: flex;
      align-items: center;
      gap: 16px;

      :global(.svg-icon) {
        height: 24px;
        width: 24px;
      }

      :global(.svg-icon--phone) {
        fill: none;
        stroke-width: 2px;
        stroke: $color-black;
      }
    }
  }

  .infoP {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    font-size: 18px;
    line-height: 28px;
    @include breakpoint(desktop-screen) {
      white-space: nowrap;
    }
    span {
      text-transform: uppercase;
      font-family: $font-sans;
      letter-spacing: 0.005em;
      text-align: right;
      white-space: pre-line;
      align-self: flex-end;
    }
  }
}



.btnWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;

  &[data-column] {
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
  }
}

