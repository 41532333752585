@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.container {

  .content {
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 32px 16px;
    text-align: center;
    background-color: $color-warm-grey-light;
    box-shadow: 0px 12px 40px 10px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    @include breakpoint(desktop-screen) {
      padding: 32px 80px;
      border-radius: 2px 2px 100px 2px;
    }

    h2 {
      max-width: 222px;
      @include breakpoint(desktop-screen) {
        max-width: 462px;
      }
    }
  }

}