@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  width: 100%;
  padding: 16px 40px 40px;
  background-color: $color-white;
  @include breakpoint(desktop-screen) {
    padding: 60px 40px 30px;
  }

  .backBtn {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 4px;
    left: 0;
    z-index: 3;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      margin-bottom: 16px;
    }

    h1 {
      font-weight: 400;
      font-size: 60px;
      line-height: 64px;
      letter-spacing: 0.005em;
    }

    h2 {
      font-size: 32px;
      line-height: 38px;
      letter-spacing: 0.005em;
      margin-top: 6px;
    }
  }

  .detailsWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: 22px 0 26px;
    @include breakpoint(desktop-screen) {
      flex-direction: row;
      justify-content: center;
    }
  }

  .bio, .cover {
    color: $color-para-grey;
    margin: 0 auto;
    width: fit-content;
  }

  .cover {
    margin-top: 26px;
  }

  .infoWrap {
    width: fit-content;
    margin: 26px auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    a {
      text-transform: none;
      display: flex;
      align-items: center;
      gap: 16px;

      :global(.svg-icon) {
        height: 24px;
        width: 24px;
      }

      :global(.svg-icon--phone) {
        fill: none;
        stroke-width: 2px;
        stroke: $color-black;
      }
    }
  }

  .btnWrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
  }
}