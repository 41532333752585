@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.linkWrap {
  margin-top: 16px !important;
}

.errorMsg {
  width: 100%;
  text-align: center;
  color: $color-error;
}