/* SETTINGS */
@import 'settings/normalize';
@import 'settings/fonts';
@import 'settings/mixins';
@import 'settings/vars';

/* GLOBAL */
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  padding-top: $header-height-mobile;
  color: $color-black;
  font-family: $font-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.005em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-black;

  @include breakpoint(desktop-screen) {
    padding-top: $header-height-desktop;
  }
}

em {
  font-style: italic;
}

p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.005em;
  @include breakpoint(desktop-screen) {
    line-height: 26px;
  }
}

* {
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $color-white;
}

main {
  width: 100%;
  flex-grow: 1;
  background-color: $color-white;
  z-index: 1;
}

footer {
  z-index: 0;
}

.content-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 32px;
  @include breakpoint(desktop-screen) {
    padding: 32px 32px 100px;
  }

  &--no-padding-m {
    padding: 0;
  }

  &--no-padding {
    padding: 0;
    @include breakpoint(desktop-screen) {
      padding: 0 0 80px;
    }
  }
}

.content {
  width: 100%;
  max-width: $max-width-content;
  position: relative;
  z-index: 0;
}

.eyebrow {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2px;
  font-family: $font-sans;
  text-transform: uppercase;
  display: inline-block;
  white-space: nowrap;
  height: min-content;
  max-width: 100%;

  &.error {
    color: $color-error;
  }

  &.pages {
    margin-bottom: 0 !important;
    text-align: center;
  }

  &.center {
    width: 100%;
    text-align: center;
  }
}

h1, h2 {
  font-family: $font-display;
  font-style: italic;
  font-weight: 300;
  font-size: 42px;
  line-height: 42px;
  letter-spacing: -0.01em;
  span {
    font-style: normal;
  }
  @include breakpoint(desktop-screen) {
    font-size: 48px;
    line-height: 52px;
  }
}

.eyebrow-hl-wrap {
  gap: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.recaptcha-wrap {
  height: 78px;
  /* display: flex;
  justify-content: center; */

  /* * {
    width: 100% !important;
  } */
  /* background: $color-off-white;
  border: 1px solid $color-ui-grey-1;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
  border-radius: 3px; */
}

.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

[data-notify-count]::after {
  content: attr(data-notify-count);
  font-size: 14px;
  font-family: $font-sans;
  color: $color-black;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: $color-salmon;
  display: grid;
  align-content: center;
  position: absolute;
  top: -11px;
  right: -11px;
  z-index: 2;
}

/* LIBS */
@import 'lib/backgrounds';
@import 'lib/buttons-links';
@import 'lib/cards';
@import 'lib/forms';
@import 'lib/icons';
@import 'lib/loading';
@import 'lib/modal';
@import 'lib/snob_quotes';
@import 'lib/profile-image';
@import 'lib/date-picker';

