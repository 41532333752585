@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  @include breakpoint(desktop-screen) {
    padding: 10px 60px 30px;
    background-color: $color-ui-grey-6;
  }

  .header {
    width: 100%;
    padding: 16px;
    background-color: $color-ui-grey-6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include breakpoint(desktop-screen) {
      padding: 0;
    }

    h2 {
      font-style: normal;
      font-size: 28px;
      line-height: 32px;
      text-align: center;
      @include breakpoint(desktop-screen) {
        font-size: 32px;
        line-height: 38px;
        text-align: left;
      }
    }

    .deleteBtn {
      height: 32px;
      width: 32px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;
    margin-top: 16px;
    @include breakpoint(desktop-screen) {
      gap: 26px;
      padding: 0;
    }

    /* input {
      text-transform: unset !important;
    } */
  }

  .snobTipWrap {
    display: none;
    @include breakpoint(desktop-screen) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .pallet {
    padding: 20px 32px;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &:first-child {
      @include breakpoint(desktop-screen) {
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 100px;

        >* {
          height: min-content;
          grid-column-end: 2;
          &:first-child {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 7;
            height: 100%;
          }
          &:last-child {
            grid-column-start: 1;
            grid-column-end: 3;
          }
        }
      }
    }
  }

  .textAreaContainer {
    width: 100%;
    border: none;
    textarea {
      min-height: 40px;
    }
    textarea,
    input {
      max-width: 600px;
    }
    input {
      width: 100%;
      padding: 8px 12px 9px;
      border-radius: 8px;
      border: 1px solid $color-ui-grey-1;
      text-align: left !important;
    }

    :global(.input-error) {
      position: unset;
    }
  }

  :global(label) {
    @include breakpoint(desktop-screen) {
      white-space: nowrap;
    }
  }
  
}

.btnWrap {
  width: 100%;
  margin-top: 44px;
  padding: 16px 32px 36px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  
  @include breakpoint(desktop-screen) {
    padding: 16px 180px 36px;
    background-color: transparent;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 22px 32px;

    > * {
      width: 30%;
      flex-grow: 1;
    }
  }

  &[data-modal] {
    padding: 0;
    margin: 0;
  }
}