@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 40px 40px;
  background-color: $color-ui-grey-6;
  @include breakpoint(desktop-screen) {
    display: grid;
    grid-template-columns: repeat(2, 520px);
    padding: 0 32px 30px;

    > *:nth-child(1) {
      grid-area: 1/1;
    }
    > *:nth-child(2) {
      grid-area: 1/2;
      height: fit-content;

      &[data-measured=true] {
        height: auto;
      }
    }
  }
}

.statusWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background-color: $color-ui-grey-6;
  padding: 16px;
  @include breakpoint(desktop-screen) {
    flex-direction: row;
    padding: 24px;
  }
}

.btnWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background-color: $color-ui-grey-6;
  padding: 0 40px 40px;
  @include breakpoint(desktop-screen) {
    flex-direction: row;
    padding: 0 32px 30px;
  }
}