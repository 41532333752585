@import '/src/scss/settings/vars';

$colors: (
  "black": $color-black,
  "hover-black": $color-hover-black,
  "white": $color-white,
  "red": $color-error,
  "green": $color-success,
  "salmon": $color-salmon,
  "earthy-grey": $color-earthy-grey,
  "accent-grey": $color-accent-grey,
  "ui-grey-2": $color-ui-grey-2,
  "stone-grey": $color-stone-grey,
  "ice-grey": $color-ice-grey
);

.svg-icon {
  width: 100%;
  height: 100%;

  @each $color, $i in $colors {
    &.#{$color} {
      fill: $i;
    }
  }

  use {
    transition: transform 0.34s ease-out;
  }
}