@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  @include breakpoint(desktop-screen) {
    padding: 10px 60px 30px;
    background-color: $color-ui-grey-6;
  }

  .header {
    width: 100%;
    padding: 16px;
    background-color: $color-ui-grey-6;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    @include breakpoint(desktop-screen) {
      flex-direction: row;
      justify-content: space-between;
      padding: 0;

      .showingP {
        position: absolute;
        width: calc(100% - 70px);
        text-align: center;
        pointer-events: none;
        transition: width 0.28s ease-out;
        &[data-anim-width=true] {
          width: calc(100% - 302px);
        }
      }
    }

    h2 {
      font-style: normal;
      font-size: 28px;
      line-height: 32px;
      text-align: center;
      text-transform: capitalize;
      @include breakpoint(desktop-screen) {
        font-size: 32px;
        line-height: 38px;
        text-align: left;
        white-space: nowrap;
      }
    }

    .searchFilterWrap {
      display: flex;
      flex-direction: row;
      gap: 16px;
      justify-content: flex-end;
      position: relative;
      width: 100%;
    }
  }

  .jobInfoWrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;
    margin-top: 16px;
    @include breakpoint(desktop-screen) {
      gap: 26px;
      padding: 0;

      &[data-side-by-side=true] {
        flex-direction: row;
        > * {
          max-width: unset !important;
        }

        :global(.eyebrow) {
          white-space: normal;
        }
      }
    }
  }

  .resultsWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 16px;
  }

  .profileWrap {
    width: 100%;
    margin-top: 16px;
    padding: 32px;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.btnWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
}