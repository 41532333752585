@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  display: flex;
  flex-direction: column;
  padding: 16px;
  @include breakpoint(desktop-screen) {
    background-color: $color-ui-grey-6;
    padding: 0 28px 32px;
    gap: 26px;
  }

  .cardWrap{
    background-color: $color-white;
    border-radius: 4px 4px 0px 0px;
    @include breakpoint(desktop-screen) {
      border-bottom: 2px solid #DADFDE;
      border-radius: 4px;
    }
  }

  h2 {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    background-color: $color-ui-grey-6;
    padding-top: 32px;
    @include breakpoint(desktop-screen) {
      padding-top: 0;
    }
  }

  form {
    background-color: $color-ui-grey-6;
    gap:32px;
    padding: 32px 16px;
    @include breakpoint(desktop-screen) {
      padding: 0;
    }
  }

  :global(.form-sub-group--border) {
    border-color: $color-black;
    @include breakpoint(desktop-screen) {
      width: 458px;
    }
  }

  .btnWrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    @include breakpoint(desktop-screen) {
      gap: 16px;
    }

    p {
      width: 100%;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: $color-hover-black;
    }

    :global(.btn--dt-wide) {
      max-width: 478px;
    }
  }

}
