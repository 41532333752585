.loading-inview {
  font-family: $font-sans;
  font-size: 18px;
  color: $color-stone-grey;
  width: 100%;
  height: 24px;
  
  text-transform: uppercase;
  &::before {
    content: 'loading...'
  }
  &--center {
    text-align: center;
  }
  &--mt {
    margin-top: 8px;
  }
}

[data-mobile] {
  @include breakpoint(desktop-screen) {
    display: none !important;
  }
}

[data-desktop] {
  display: none !important;
  @include breakpoint(desktop-screen) {
    display: inherit !important;
  }
}