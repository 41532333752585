.bg1-light {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), 
              url(/images/bg-image-1-m.jpg);
  background-size: cover;
  @include breakpoint(tablet-screen) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), 
                url(/images/bg-image-1.jpg);
    background-size: cover;
  }
}

.bg1-dark {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.73), rgba(0, 0, 0, 0.73)), 
              linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
              url(/images/bg-image-1-m.jpg);
  background-size: cover;
  @include breakpoint(tablet-screen) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.73), rgba(0, 0, 0, 0.73)), 
                linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
                url(/images/bg-image-1.jpg);
    background-size: cover;
  }
}

.bg1-light-alt {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 75%, $color-warm-grey-light 75%, 
                  $color-warm-grey-light 100%), url(/images/bg-image-1-m.jpg);
  background-size: 100%, 100% 75%;
  @include breakpoint(tablet-screen) {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 75%, $color-warm-grey-dark 75%, 
                  $color-warm-grey-dark 100%), url(/images/bg-image-1.jpg);
    background-size: 100%, 100% 75%;
  }
}

.bg-account {
  background: linear-gradient(180deg, $color-account-bg 0%, rgba(247, 212, 198, 0) 64.89%), 
              linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), 
              url(/images/account-bg-m.jpg) no-repeat;
  background-size: 100% auto;
  background-position: bottom;
  background-color: $color-account-bg;
  @include breakpoint(desktop-screen) {
    background: linear-gradient(180deg, $color-account-bg 0%, rgba(247, 212, 198, 0) 64.89%), 
                linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), 
                url(/images/account-bg.jpg) no-repeat;
    background-size: 100% auto;
    background-position: bottom;
    background-color: $color-account-bg;
  }
}

.bg2 {
  background-image: url(/images/bg-image-2-m.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  @include breakpoint(tablet-screen) {
    background-image: url(/images/bg-image-2.jpg);
  }
}

.bg-purchase {
  background-image: url(/images/purchase-bg-m.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  @include breakpoint(tablet-screen) {
    background-image: url(/images/purchase-bg.jpg);
  }
}

.bg-purchase-success {
  background-image: url(/images/success-bg-m.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  @include breakpoint(tablet-screen) {
    background-image: url(/images/success-bg.jpg);
  }
}