@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.form-wrap {
  width: 100%;
  background-color: $color-warm-grey-light;
  border-radius: 2px 100px 0px 0px;
  padding: 56px 16px 60px;
  position: relative;
  @include breakpoint(desktop-screen) {
    width: 684px;
    padding: 51px 103px 60px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 170px;
    position: absolute;
    top: 0;
    right: 0px;
    border-radius: 2px 100px 0px 0px;
    box-shadow: 0px 12px 40px 10px rgba(0, 0, 0, 0.1);
    z-index: -1;
  }

  form {
    margin-top: 56px;
    gap: 24px;
  }

  .link-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -2px;
  }

  a {
    color: $color-hover-black;
    text-decoration: underline;
    text-underline-offset: 4px;
    font-size: 14px;
    line-height: 16px;
  }

  .add-margin {
    margin: 6px 0;
  }
  
  .btn {
    max-width: unset;
  }
}