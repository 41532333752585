@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content-wrap {
  padding: 0;
  @include breakpoint(desktop-screen) {
    padding-bottom: 100px;
  }

  .header {
    width: 100%;
    padding: 40px 0 52px;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint(desktop-screen) {
      padding: 48px 0 32px;
    }

    h1 {
      font-size: 52px;
      line-height: 52px;
      font-style: normal;
      letter-spacing: 0;
      @include breakpoint(desktop-screen) {
        font-size: 60px;
        line-height: 64px;
      }
    }
  }

  :global(.content) {
    display: grid;
    gap: 32px;
    padding: 16px 16px 32px;
    @include breakpoint(desktop-screen) {
      display: block;
      columns: 2;
      gap: 80px;
      padding: 30px 61px;
      background-color: $color-ui-grey-6;
    }
  }

  .avatarWrap,
  .avatar {
    width: 140px;
    height: 140px;
  }

  .avatarWrap {
    position: relative;

    .deleteImgBtn {
      width: 24px;
      height: 24px;
      position: absolute;
      right: -12px;
      top: -12px;
      background-color: $color-black;
      border-radius: 50%;
      padding: 2px;

      svg {
        pointer-events: none;
      }
    }
  }

  

  .resumeItem {
    padding: 16px 12px;
    border: 0.5px solid $color-accent-grey;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    :global(.svg-icon--trash) {
      width: 24px;
      height: 24px;
    }
  }

  .cta {
    width: 100%;
    padding: 16px 32px 36px;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint(desktop-screen) {
      padding: 28px 30px 30px;
      max-width: $max-width-content;
      background-color: $color-ui-grey-6;
    }
  }
}

.pallet {
  padding: 32px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto; // <-- fixes issue w Firefox
  @include breakpoint(desktop-screen) {
    break-inside: avoid-column;
    height: fit-content;
    margin-bottom: 32px;
  }

  p:not(:global(.eyebrow)) {
    color: $color-hover-black;
    font-size: 18px;
    line-height: 20px;
  }

  :global(.eyebrow) {
    text-align: center;
  }

  :global(select.invalid),
  ::placeholder {
    color: $color-ui-grey-3;
  }

  textarea {
    resize: none;
  }

  :global(.btn--file) {
    @include breakpoint(desktop-screen) {
      width: 204px;
      letter-spacing: -0.01em;
      padding: 0;
    }
  }

  :global(.btn--save) {
    @include breakpoint(desktop-screen) {
      width: auto;
      margin: 2px auto 0;
    }
  }

  :global(.form-sub-group) {
    input:not([type=checkbox]):not([type=radio]) {
      width: 20%;
    }
  }
}

.pallet[data-candidate=true] {
  &:nth-child(3) p,
  &:nth-child(4) p:last-of-type {
    line-height: 28px;
  }

  /* Resume */
  &:nth-child(3) {
    break-before: avoid;
  }

  /* Work Info */
  &:nth-child(4) {
    grid-row-start: 3;
  }

  /* Change PW */
  &:nth-child(6) {
    grid-row-start: 4;
  }
}

.pallet[data-employer=true] {
  input {
    text-transform: unset !important;
  }
  
  &:nth-child(3) {

    :global(.form-sub-group):nth-child(7),
    :global(.form-sub-group):nth-child(8),
    :global(.form-sub-group):nth-child(9),
    :global(.form-sub-group):nth-child(10),
    :global(.form-sub-group):nth-child(11) {
      /* height: 44px; */

      input {
        /* position: absolute; */
        width: 10%;
      }
    }
    
    /* [data-attr]::before {
      content: attr(data-attr);
      opacity: 0;
    }
    [data-attr-2]::after {
      content: attr(data-attr-2);
    } */
  }
}