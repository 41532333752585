@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.topContainer {
  height: auto;
  padding: 0;
  background-color: $color-white;
  position: relative;
  z-index: 1;

  &[data-widget] {
    margin-top: -$header-height-mobile;
    @include breakpoint(desktop-screen) {
      margin-top: -$header-height-desktop;
    }
  }
  

  .content {

    .header {
      display: grid;
      width: 100%;
      height: 800px;
      background-color: #000;
      @include breakpoint(desktop-screen) {
        grid-template-columns: auto 145px 595px;
        height: 686px;
        background-color: unset;
      }

      .imgWrap {
        grid-area: 1/1;
        overflow: hidden;
        align-self: end;
        @include breakpoint(desktop-screen) {
          grid-column-start: 2; 
          grid-column-end: 4;
          align-self: unset;
        }

        picture {
          display: grid;
          justify-content: center;
        }

        img {
          width: 100%;
          object-fit: cover;
          transform: translateY(30%);
          @include breakpoint(desktop-screen) {
            transform: unset;
          }
        }
      }

      .msgWrap {
        grid-area: 1/1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        margin: 16px;
        padding: 32px 24px;
        height: fit-content;
        background: $color-dusty-pink;
        box-shadow: 0px 12px 40px 10px rgba(0, 0, 0, 0.1);
        border-radius: 2px 2px 100px 2px;
        z-index: 1;
        @include breakpoint(desktop-screen) {
          grid-column-end: 3; 
          margin: 0;
          padding: 168px 40px 56px;
        }

        h1 {
          width: 222px;
          @include breakpoint(desktop-screen) {
            width: auto;
          }
        }

        p:not(:global(.eyebrow)) {
          text-align: center;
          color: $color-hover-black;
          @include breakpoint(desktop-screen) {
            text-align: left;
            padding-bottom: 42px;
          }
        }
      }
    }

    .searchWrap {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      /* align-items: center; */
      gap: 16px;
      padding: 20px 16px;
      position: relative;
      @include breakpoint(desktop-screen) {
        padding: 20px 0;
      }

      .jobSearch {
        position: absolute;
        top: -($header-height-mobile - 10px);
        @include breakpoint(desktop-screen) {
          top: -($header-height-desktop - 10px);
        }
      }

      [data-notify-count]::after {
        top: 6px;
      }
    }
  }
}

.btnContainer {
  height: auto;
  padding: 20px 0;
  background-color: $color-ui-grey-6;
  :global(.btn) {
    margin: 0 auto;
  }

  @include breakpoint(desktop-screen) {
    :global(.btn) {
      float: right;
    }
  }
}

.listContainer {
  height: auto;
  background-color: $color-ui-grey-6;
  padding: 16px 16px 32px;
  position: relative;
  z-index: 0;
  @include breakpoint(desktop-screen) {
    padding: 40px 32px 60px;
  }

  :global(.eyebrow) {
    color: $color-hover-black;
    margin-bottom: 16px;
    @include breakpoint(desktop-screen) {
      margin-bottom: 24px;
    }
  }

  .listWrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    z-index: 0;
    @include breakpoint(desktop-screen) {
      gap: 24px;
    }
  }

  .btnWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  .logoPowered {
    width: 272px;
    height: 36px;
    margin: 24px auto 0;
    background: no-repeat url("/images/logo-powered.svg");
  }
}

.btnContainer[data-widget],
.listContainer[data-widget] {
  background-color: $color-widget-bg-grey;
}