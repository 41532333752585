@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.tagsWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 8px 0;
  &[data-empty=true] {
    padding: 0;
    height: 0;
  }

  .tag {
    display: flex;
    align-items: center;
    gap: 6px;
    background: $color-salmon;
    border-radius: 4px;
    padding: 0 6px;

    &[data-hidden=true] {
      height: 0;
      width: 0;
      visibility: hidden;
      position: absolute;
      pointer-events: none;
    }

    input {
      pointer-events: none;
    }

    button {
      height: 16px;
      width: 16px;
      flex-shrink: 0;
    }
  }
}