@import '/src/scss/settings/mixins';
@import '/src/scss/settings/vars';

.content {
  background-color: $color-bone-white;
  @include breakpoint(desktop-screen) {
    display: flex;
  }
  

  .quoteWrap {
    background-color: $color-sand;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    text-align: center;
    padding: 32px 16px;
    box-shadow: 0px 12px 40px 10px rgba(0, 0, 0, 0.1);

    h1 {
       max-width: 222px;
    }
    .msg, a {
      color: $color-hover-black;
    }

    @include breakpoint(desktop-screen) {
      width: 54.66549%;
      flex-shrink: 0;
      padding: 38px 80px;
      border-radius: 2px 2px 100px 2px;

      h1 {
        max-width: unset;
     }
    }
  }

  .blogWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    text-align: center;
    padding: 50px 16px 32px;

    /* img {
      height: 120px;
    } */

    img,h2 {
      width: 272px;
    }

    h2 {
      font-style: normal;
      font-size: 24px;
      line-height: 28px;
    }

    @include breakpoint(desktop-screen) {
      padding: 66px 82px;



      img,h2 {
        width: 311px;
      }
    }
  }
}